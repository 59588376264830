/*-------- Alignments -------*/

@media (min-width: 1280px) {
	.container {
		max-width: 1200px;
	}
}
.display-1 i, .display-2 i, .display-3 i, .display-4 i {
	vertical-align: baseline;
	font-size: .815em;
}
.text-inherit {
	color: inherit !important;
}
.text-default {
	color: #3f3e50 !important;
}
.text-muted-dark {
	color: #5f6877 !important;
}
.tracking-tight {
	letter-spacing: -.05em !important;
}
.tracking-normal {
	letter-spacing: 0 !important;
}
.tracking-wide {
	letter-spacing: .05em !important;
}
.leading-none {
	line-height: 1 !important;
}
.leading-tight {
	line-height: 1.3 !important;
}
.leading-normal {
	line-height: 1.5 !important;
}
.leading-normal-2 {
	line-height: 1.8 !important;
}
.leading-loose {
	line-height: 2 !important;
}
.icon i {
	vertical-align: -1px;
}
a.icon {
	text-decoration: none;
	cursor: pointer;
	&:hover {
		color: #1a1a1a !important;
	}
}
.o-auto {
	overflow: auto !important;
}
.o-hidden {
	overflow: hidden !important;
}
/*-------- Custom Styles -------*/

.shadow-sm {
	box-shadow: 0 0.125rem 0.25rem rgba(49, 45, 101, 0.075) !important;
}
.shadow {
	box-shadow: 0 0.5rem 1rem rgba(49, 45, 101, 0.15) !important;
}
.shadow-lg {
	box-shadow: 0 1rem 3rem rgba(49, 45, 101, 0.175) !important;
}
.shadow-none {
	box-shadow: none !important;
}
.text-monospace {
	font-family: Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}
.font-weight-light {
	font-weight: 300 !important;
}
.font-weight-normal {
	font-weight: 400 !important;
}
.font-weight-semibold {
	font-weight: 500 !important;
}
.font-weight-semibold2 {
	font-weight: 600 !important;
}
.font-weight-bold {
	font-weight: 700 !important;
}
.font-italic {
	font-style: italic !important;
}
/*-------- Alignments & Backgrounds -------*/