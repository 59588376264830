/*-------- Custom CSS -------*/
.embed-responsive {
	position: relative;
	display: block;
	width: 100%;
	padding: 0;
	overflow: hidden;
	&::before {
		display: block;
		content: "";
	}
	.embed-responsive-item, embed, iframe, object, video {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
	}
}
.embed-responsive-21by9::before {
	padding-top: 42.85714286%;
}
.embed-responsive-16by9::before {
	padding-top: 56.25%;
}
.embed-responsive-4by3::before {
	padding-top: 75%;
}
.embed-responsive-1by1::before {
	padding-top: 100%;
}
.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip:inherit;
	white-space: nowrap;
	border: 0;
}
.sr-only-focusable {
	&:active, &:focus {
		position: static;
		width: auto;
		height: auto;
		overflow: visible;
		clip: auto;
		white-space: normal;
	}
}
.page {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	justify-content: center;
	min-height: 100%;
}
body.fixed-header .page {
	padding-top: 4.5rem;
}
.page-main {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
}
.page-content {
	margin: .75rem 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	justify-content: center;
	min-height: 100vh;
}
.page-header {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-wrap: wrap;
	justify-content: space-between;
	padding: 0;
	position: relative;
	min-height: 50px;
	background: #fff;
	line-height: 50px;
}
.users-list .page-header {
	background: 0 0;
	padding: 0;
	border: 0;
}
.bannerimg .breadcrumb {
	display: inline-flex;
	font-size: 18px;
	font-weight: 500;
}
.page-title {
	margin: 0;
	font-size: 18px;
	font-weight: 600;
	line-height: 2.5rem;
}
.page-title-icon {
	color: #cecde0;
	font-size: 1.25rem;
}
.page-subtitle {
	font-size: .8125rem;
	color: #5f6877;
	position: relative;
	top: 19px;
	right: 59rem;
	a {
		color: inherit;
	}
}
.page-options {
	margin-left: auto;
}
.page-description {
	margin: .25rem 0 0;
	color: #5f6877;
	a {
		color: inherit;
	}
}
.page-single {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
}
.content-heading {
	font-weight: 400;
	margin: 2rem 0 1.5rem;
	font-size: 1.25rem;
	line-height: 1.25;
	&:first-child {
		margin-top: 0;
	}
}
.aside {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	width: 22rem;
	background: #fff;
	border-left: 1px solid $border-color;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	z-index: 100;
	visibility: hidden;
	box-shadow: 0 5px 20px rgba(49, 45, 101, 0.1);
}
.aside-body {
	padding: 1.5rem;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	overflow: auto;
}
.aside-footer {
	padding: 1rem 1.5rem;
	border-top: 1px solid $border-color;
}
.aside-header {
	padding: 1rem 1.5rem;
	border-bottom: 1px solid $border-color;
}
.header {
	padding-top: .75rem;
	padding-bottom: .75rem;
	width: 100%;
	z-index: 100;
}
body.fixed-header .header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1030;
}
@media print {
	.header {
		display: none;
	}
}
.header {
	.dropdown-menu {
		margin-top: 0;
	}
	.mega-menu {
		width: 350px;
	}
}
.nav-unread {
	position: absolute;
	top: .1rem;
	right: .4rem;
	background: #f18f13;
	width: .5rem;
	height: .5rem;
	border-radius: 50%;
	&.badge {
		position: absolute;
		top: 0px;
		text-align: center;
		right: 0;
		width: 1rem;
		height: 1rem;
		border-radius: 50%;
		font-size: 10px;
		padding: 0;
		line-height: 1rem;
	}
}
.nav-link.icon {
	margin: 5px;
	border-radius: 50%;
	padding: 12px;
	width: 2.5rem;
	text-align: center;
	height: 2.5rem;
	font-size: 15px;
	position: relative;
	&.full-screen-link {
		padding-bottom: 12px;
	}
	i {
		color: #fcfdff;
	}
}
.header-brand {
	color: inherit;
	margin-right: 1rem;
	font-size: 1.25rem;
	white-space: nowrap;
	font-weight: 600;
	padding: 0;
	transition: .3s opacity;
	line-height: 2.9rem;
}
.app-header .header-brand {
	color: inherit;
	margin-right: 0;
	font-size: 1.25rem;
	white-space: nowrap;
	font-weight: 600;
	padding: 0;
	transition: .3s opacity;
	line-height: 2.9rem;
	min-width: 200px;
	text-align: center;
	.header-brand-img {
		margin-right: 0;
	}
}
.app-header1 .header-brand {
	color: inherit;
	margin-right: 0;
	font-size: 1.25rem;
	white-space: nowrap;
	font-weight: 600;
	padding: 0;
	transition: .3s opacity;
	line-height: 0;
	min-width: 190px;
	margin-top: -1.5px;
	margin-left: 17px;
	.header-brand-img {
		margin-right: 0;
	}
}
.header-brand:hover {
	color: inherit;
	text-decoration: none;
}
.header-brand-img {
	height: 2.5rem;
	line-height: 2rem;
	vertical-align: bottom;
	margin-right: .5rem;
	width: auto;
}
.header-avatar {
	width: 2rem;
	height: 2rem;
	display: inline-block;
	vertical-align: bottom;
	border-radius: 50%;
}
.header-btn {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	line-height: 2rem;
	text-align: center;
	font-size: 1rem;
	&.has-new {
		position: relative;
		&:before {
			content: '';
			width: 6px;
			height: 6px;
			position: absolute;
			top: 4px;
			right: 4px;
			border-radius: 50%;
		}
	}
}
.header-toggler {
	width: 2rem;
	height: 2rem;
	position: relative;
	color: #fff;
	&:hover {
		color: #fff;
	}
}
.header-toggler-icon {
	position: absolute;
	width: 1rem;
	height: 2px;
	color: inherit;
	background: currentColor;
	border-radius: 5px;
	top: 50%;
	left: 50%;
	margin: -2px 0 0 -.5rem;
	box-shadow: 0 5px currentColor, 0 -5px currentColor;
}
.footer {
	background: #fff;
	border-top: 1px solid $border-color;
	font-size: .875rem;
	padding: 1.25rem 0;
	color: #a1a0b3;
	.social {
		ul li {
			float: left;
			padding: 7px;
			a {
				color: #cecde0;
			}
		}
		margin: 0 auto;
	}
	a:not(.btn) {
		color: #1f252d;
	}
}
@media print {
	.footer {
		display: none;
	}
}
/*-------- Custom Styles -------*/

/*-------- Navigation -------*/

/*-------- Default Styles -------*/

.alert-icon {
	padding-left: 3rem;
	>i {
		color: inherit !important;
		font-size: 1rem;
		position: absolute;
		top: 1rem;
		left: 1rem;
	}
}
.alert-avatar {
	padding-left: 3.75rem;
	.avatar {
		position: absolute;
		top: .5rem;
		left: .75rem;
	}
}
.expanel-default {
	border: #ddd !important;
}
.expanel-primary {
	border: #eef2f9 !important;
}
.expanel-success {
	border: #3eb750 !important;
}
.expanel-danger {
	border: #fe6567 !important;
}
.expanel-primary>.expanel-heading {
	color: #7b838f !important;
	background-color: $border-color !important;
	border-color: #eef2f9 !important;
}
.expanel-success>.expanel-heading {
	color: #fff !important;
	background-color: #3eb750 !important;
	border-color: #3eb750 !important;
}
.expanel-danger>.expanel-heading {
	color: #fff !important;
	background-color: #fe6567 !important;
	border-color: #fe6567 !important;
}
.expanel-warning>.expanel-heading {
	color: #fff !important;
	background-color: #ecd938 !important;
	border-color: #ecd938 !important;
}
.expanel-title {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	font-size: 16px !important;
	color: inherit !important;
}
.expanel {
	margin-bottom: 20px !important;
	background-color: #fff !important;
	border: 1px solid $border-color !important;
	border-radius: 4px !important;
	-webkit-box-shadow: 0 1px 1px rgba(49, 45, 101, 0.05) !important;
	box-shadow: 0 1px 1px rgba(49, 45, 101, 0.05) !important;
}
.expanel-default>.expanel-heading {
	background-color: $border-color !important;
	border: 1px solid $border-color !important;
}
.expanel-heading {
	padding: 10px 15px !important;
	border-bottom: 1px solid $border-color;
	border-top-left-radius: 3px !important;
	border-top-right-radius: 3px !important;
}
.expanel-footer {
	padding: 10px 15px !important;
	background-color: $border-color !important;
	border-top: 1px solid $border-color !important;
	border-bottom-right-radius: 3px !important;
	border-bottom-left-radius: 3px !important;
}
.expanel-body {
	padding: 15px !important;
}
.thumbnail {
	display: block;
	padding: 4px;
	margin-bottom: 20px;
	line-height: 1.42857143;
	background-color: #fff;
	border: 1px solid $border-color;
	border-radius: 4px;
	-webkit-transition: border .2s ease-in-out;
	-o-transition: border .2s ease-in-out;
	transition: border .2s ease-in-out;
	a>img, >img {
		margin-right: auto;
		margin-left: auto;
	}
	p:last-child {
		margin-bottom: 0;
	}
}
.thumbimg {
	height: 100%;
	width: 100%;
	display: block;
}
.thumbnail .caption {
	padding: 20px;
}
.about-img {
	background: url(../images/other/about.jpg);
	background-size: cover;
	background-position: center;
	border-radius: 2px;
	margin-top: 1.5rem;
}
.login-img {
	background: url(../images/other/login.jpg);
	background-size: cover;
	width: 100%;
	height: 100%;
}
.error-img {
	background: url(../images/other/about.jpg);
	background-size: cover;
	width: 100%;
	height: 100%;
	.card {
		background: rgba(255, 255, 255, 0.2);
		box-shadow: 0 0.0625em 0.1875em 0 #076271, 0 0.5em 0 -0.25em rgba(255, 255, 255, 0.3), 0 0.5em 0.1875em -0.25em #086372, 0 1em 0 -0.5em rgba(255, 255, 255, 0.35), 0 1em 0.1875em -0.5em #096675;
	}
	&:before {
		content: '';
		display: block;
		position: absolute;
		background: linear-gradient(to right, rgba(15, 117, 255, 0.8), rgba(45, 220, 211, 0.8));
		width: 100%;
		height: 100%;
		right: 0;
		top: 0;
	}
}
hr.divider {
	padding: 0;
	border: none;
	border-top: solid 1px $border-color;
	color: $text-color;
	text-align: center;
	margin: .5rem 0;
	&:after {
		content: "OR";
		display: block;
		position: relative;
		top: -1.5em;
		font-size: 11px;
		padding: 0 .25em;
		background: $border-color;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		z-index: 9;
		left: 0;
		right: 0;
		line-height: 30px;
		font-weight: 500;
		border: 1px solid $border-color;
		margin: 0 auto;
	}
}
.construction-img {
	background: url(../images/other/construction.jpg);
	background-size: cover;
}
.page-breadcrumb {
	background: 0 0;
	padding: 0;
	margin: 1rem 0 0;
	font-size: .875rem;
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
	.breadcrumb-item {
		color: #cecde0;
		&.active {
			color: #5f6877;
		}
	}
}
.pagination-simple .page-item {
	.page-link {
		background: 0 0;
		border: none;
	}
	&.active .page-link {
		color: #3d4e67;
		font-weight: 700;
	}
}
.pagination-pager {
	.page-prev {
		margin-right: auto;
	}
	.page-next {
		margin-left: auto;
	}
}
.page-total-text {
	margin-right: 1rem;
	-ms-flex-item-align: center;
	align-self: center;
	color: #5f6877;
}
.popover {
	&.bs-popover-auto[x-placement^=top], &.bs-popover-top {
		margin-bottom: .625rem;
	}
}
.popover-static-demo .popover {
	.arrow {
		margin-left: calc(.25rem + 122px);
	}
	position: relative;
}
.dropdown {
	display: block;
}
.dropdown-menu.drop-menu-width {
	min-width: 22rem;
}
.app-selector {
	box-shadow: 0 1px 2px 0 rgba(49, 45, 101, 0.05);
	min-width: 16rem;
}
.visitor-list {
	p {
		font-size: 12px;
	}
	i {
		font-size: 20px;
	}
}
.sales-relative {
	position: relative;
}
.icon-absolute {
	position: absolute;
	display: block;
	bottom: 10px;
	right: 10px;
	padding: 20px 0;
	text-align: center;
	border-radius: 50%;
	width: 70px;
	height: 70px;
}
.country-selector .avatar {
	width: 1.4rem;
	height: 1.4rem;
	line-height: 1rem;
	border-radius: 50%;
	border: 1px solid $border-color;
}
/*-------- Default Styles -------*/

/*-------- Default Styles2 -------*/

.mail-chats {
	height: 100%;
	min-height: 0;
	border-top: 1px solid $border-color;
	margin-top: 10px;
	padding: 10px 50px 10px 0;
	width: 100%;
	list-style-type: none;
	flex-direction: column !important;
	display: flex !important;
	li.chat-persons {
		padding: 10px;
		display: block;
	}
}
li.chat-persons a {
	text-decoration: none;
}
.mail-chats li.chat-persons a {
	span.pro-pic {
		img {
			max-width: 100%;
			width: 100%;
			-webkit-border-radius: 100%;
			-moz-border-radius: 100%;
			-ms-border-radius: 100%;
			border-radius: 100%;
			flex-direction: column !important;
		}
		display: inline-block;
		padding: 0;
		width: 20%;
		max-width: 40px;
		float: left;
		margin-right: 20px;
	}
	div.user {
		flex-direction: column !important;
	}
}
ul.mail-chats li.chat-persons a div.user {
	display: flex !important;
}
.mail-chats li.chat-persons.user {
	width: 80%;
	padding: 5px 10px 0 15px;
	flex-direction: column !important;
	display: flex !important;
}
.user p {
	&.u-name {
		margin: 0;
		font-size: 14px;
		font-weight: 600;
		line-height: 18px;
		color: #cecde0;
	}
	&.u-designation {
		margin: 0;
		font-size: 11px;
		color: #cecde0;
	}
}
div.online-status .status.online {
	background: rgba(0, 128, 0, 0.68);
}
.online-status .status {
	&.offline {
		background: rgba(255, 0, 0, 0.7);
	}
	height: 10px;
	width: 10px;
	-webkit-border-radius: 100%;
	-moz-border-radius: 100%;
	-ms-border-radius: 100%;
	border-radius: 100%;
	display: inline-flex;
	justify-content: flex-start;
	transform: translateX(-60px) translateY(2px);
}
.online {
	margin-left: 20px;
	margin-top: -3px;
}
.icons-list {
	list-style: none;
	padding: 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	>li {
		-ms-flex: 1 0 4rem;
		flex: 1 0 4rem;
	}
}
.icons-list-wrap {
	overflow: hidden;
}
.icons-list-item {
	text-align: center;
	height: 4rem;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin: 4px;
	border: 1px solid $border-color;
	border-radius: 5px;
	background: $body-color;
	i {
		font-size: 30px;
		color: #aba9c2;
	}
}
.img-gallery {
	margin-right: -.25rem;
	margin-left: -.25rem;
	margin-bottom: -.5rem;
	> {
		.col, [class*=col-] {
			padding-left: .25rem;
			padding-right: .25rem;
			padding-bottom: .5rem;
		}
	}
}
.link-overlay {
	position: relative;
	&:hover .link-overlay-bg {
		opacity: 1;
	}
}
.link-overlay-bg {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(49, 45, 101, 0.5);
	display: -ms-flexbox;
	display: flex;
	color: #fff;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	font-size: 1.25rem;
	opacity: 0;
	transition: .3s opacity;
}
.media-icon {
	width: 2rem;
	height: 2rem;
	line-height: 2rem;
	text-align: center;
	border-radius: 100%;
}
.media-list {
	margin: 0;
	padding: 0;
	list-style: none;
}
textarea[cols] {
	height: auto;
}
.form-group {
	display: block;
	margin-bottom: 1rem;
}
.form-label {
	display: block;
	margin-bottom: .375rem;
	font-weight: 500;
	font-size: .875rem;
}
.form-label-small {
	float: right;
	font-weight: 400;
	font-size: 87.5%;
}
.form-footer {
	margin-top: 2rem;
}
.custom-controls-stacked .custom-control {
	margin-bottom: .25rem;
}
.custom-control-label:before {
	border: 1px solid #c6c0de;
	background-color: #fff;
	background-size: .5rem;
}
.custom-control-description {
	line-height: 1.5rem;
}
.input-group-append, .input-group-btn, .input-group-prepend {
	font-size: .9375rem;
}
.input-group-append>.btn, .input-group-btn>.btn {}
.input-group-prepend> {
	.btn {}
	.input-group-text {
		border-right: 0;
	}
}
.input-group-append>.input-group-text {
	border-left: 0;
}
.widgets {
	font-size: 35px;
	padding: 20px;
}
.widgetstext {
	top: 0;
	bottom: 0;
	padding: 20px 40px;
}
.widget-line h4 {
	font-size: 24px;
	font-weight: 600;
}
.fs {
	font-size: 25px;
}
.widget-line p {
	font-size: 16px;
}
.widget-line-list li {
	display: inline-block;
	font-size: 16px;
	line-height: 27px;
	padding: 5px 20px 0 15px;
	list-style-type: none;
}
.searching1 i {
	position: absolute;
	top: 21px;
	margin-left: 15px;
	font-size: 16px;
	color: #fcfdff;
}
.search-inline {
	width: 100%;
	left: 0;
	padding-top: 1.5rem;
	padding-bottom: .75rem;
	top: 0;
	position: absolute;
	opacity: 0;
	visibility: hidden;
	background-color: #fff;
	box-shadow: 0 0 0 1px rgba(243, 247, 253, 0.12), 0 8px 16px 0 rgba(243, 247, 253, 0.24);
	z-index: 9;
	transition: all .1s ease-in-out;
	&.search-visible {
		opacity: 1;
		visibility: visible;
		padding-top: 3rem;
		right: 0;
		padding-bottom: .5rem;
		border: 1px solid $border-color;
		animation: fadeInDown .1s ease-in-out;
	}
	button[type=submit] {
		position: absolute;
		right: 52px;
		top: 0;
		background-color: transparent;
		border: 0;
		top: 0;
		right: 80px;
		padding: 0;
		cursor: pointer;
		width: 80px;
		height: 99%;
		background: #fff;
		color: #cecde0;
	}
	.form-control {
		border: 0;
		font-size: 15px;
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 99%;
		font-weight: 700;
		outline: 0;
	}
	.search-close {
		position: absolute;
		top: 21px;
		right: 0;
		color: #cecde0;
		width: 80px;
		height: 100%;
		text-align: center;
		display: table;
		background: #fff;
		text-decoration: none;
		i {
			display: table-cell;
			vertical-align: middle;
		}
	}
}
@keyframes fadeInDown {
	from {
		opacity: 0;
		transform: translate3d(0, -20%, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 20%, 0);
	}
}
.form-control.header-search {
	background: rgba(255, 255, 255, 0.1);
	border: 1px solid rgba(255, 255, 255, 0.2) !important;
	color: #fff;
	width: 100%;
	border-radius: 12em;
	&::placeholder {
		color: #fff;
	}
	&:focus, &:hover {
		border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
		border: 0;
		box-shadow: none;
	}
}
.input-icon {
	position: relative;
	.form-control {
		&:not(:last-child), &:not(:first-child) {
			padding-left: 2.5rem;
		}
	}
}
.input-icon-addon {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	min-width: 2.5rem;
	pointer-events: none;
	&:last-child {
		left: auto;
		right: 0;
	}
}
.form-fieldset {
	background: #f8f9fa;
	border: 1px solid $border-color;
	padding: 1rem;
	border-radius: 5px;
	margin-bottom: 1rem;
}
.form-required {
	color: #fe6567;
	&:before {
		content: ' ';
	}
}
.state-valid {
	padding-right: 2rem;
	background: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%235eba00' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'><polyline points='20 6 9 17 4 12'></polyline></svg>") no-repeat center right 0.5rem / 1rem;
}
.state-invalid {
	padding-right: 2rem;
	background: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23cd201f' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-x'><line x1='18' y1='6' x2='6' y2='18'></line><line x1='6' y1='6' x2='18' y2='18'></line></svg>") no-repeat center right 0.5rem / 1rem;
}
.form-help {
	display: inline-block;
	width: 1rem;
	height: 1rem;
	text-align: center;
	line-height: 1rem;
	color: #cecde0;
	background: #f8f9fa;
	border-radius: 50%;
	font-size: .75rem;
	transition: .3s background-color, .3s color;
	text-decoration: none;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	&:hover, &[aria-describedby] {
		background: $color-blue;
		color: #fff;
	}
}
.sparkline {
	display: inline-block;
	height: 2rem;
}
.jqstooltip {
	box-sizing: content-box;
	font-family: inherit !important;
	background: $text-color !important;
	border: none !important;
	border-radius: 5px;
	font-size: 11px !important;
	font-weight: 700 !important;
	line-height: 1 !important;
	padding: 6px !important;
	.jqsfield {
		font: inherit !important;
	}
}
.social-links li a {
	background: #f8f8f8;
	border-radius: 50%;
	color: #cecde0;
	display: inline-block;
	height: 1.75rem;
	width: 1.75rem;
	line-height: 1.75rem;
	text-align: center;
}
.chart, .map {
	position: relative;
	padding-top: 56.25%;
}
.chart-square, .map-square {
	padding-top: 100%;
}
.chart-content, .map-content {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
.map-header {
	height: 15rem;
	position: relative;
	&:before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 10rem;
		background: linear-gradient(to bottom, rgba(245, 247, 251, 0) 5%, $border-color 95%);
		pointer-events: none;
	}
}
.map-header-layer {
	height: 100%;
}
.map-static {
	height: 120px;
	width: 100%;
	max-width: 640px;
	background-position: center center;
	background-size: 640px 120px;
}
@-webkit-keyframes status-pulse {
	0%, 100% {
		opacity: 1;
	}
	50% {
		opacity: .32;
	}
}
@keyframes status-pulse {
	0%, 100% {
		opacity: 1;
	}
	50% {
		opacity: .32;
	}
}
.status-icon {
	content: '';
	width: .5rem;
	height: .5rem;
	display: inline-block;
	background: currentColor;
	border-radius: 50%;
	-webkit-transform: translateY(-1px);
	transform: translateY(-1px);
	margin-right: .375rem;
	vertical-align: middle;
}
.status-animated {
	-webkit-animation: 1s status-pulse infinite ease;
	animation: 1s status-pulse infinite ease;
}
.chart-circle {
	display: block;
	height: 8rem;
	width: 8rem;
	position: relative;
	margin: 0 auto;
	canvas {
		margin: 0 auto;
		display: block;
		max-width: 100%;
		max-height: 100%;
	}
}
.chart-circle-xs {
	height: 2.5rem;
	width: 2.5rem;
	font-size: .8rem;
}
.chart-circle-xs1 {
	height: 110px;
	width: 110px;
	font-size: .8rem;
}
.chart-circle-sm {
	height: 4rem;
	width: 4rem;
	font-size: .8rem;
}
.chart-circle-lg {
	height: 10rem;
	width: 10rem;
	font-size: .8rem;
}
.chart-circle-value {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	bottom: 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-direction: column;
	flex-direction: column;
	line-height: 1;
	small {
		display: block;
		color: #cecde0;
		font-size: .9375rem;
	}
}
.sparkgraph {
	display: inline-block;
	vertical-align: top;
	width: 100%;
	height: 43px;
	canvas {
		width: 100% !important;
	}
}
.chips {
	margin: 0 0 -.5rem;
}
.team i {
	margin-left: 10px;
	float: right;
	margin-top: 10px;
	color: #313148;
}
.chips .chip {
	margin: 0 .5rem .5rem 0;
}
.chip {
	display: inline-block;
	height: 2rem;
	line-height: 2rem;
	font-size: .875rem;
	font-weight: 500;
	color: #3f3e50;
	margin: 2px;
	padding: 0 .75rem;
	border-radius: 1rem;
	background-color: #f6f7fb;
	transition: .3s background;
	.avatar {
		float: left;
		margin: 0 .5rem 0 -.75rem;
		height: 2rem;
		width: 2rem;
		border-radius: 50%;
	}
}
a.chip:hover {
	color: #fff;
	text-decoration: none;
}
.emp-tab table {
	margin-bottom: 0;
	border: 0;
}
.stamp {
	color: #fff;
	background: #868e96;
	display: inline-block;
	min-width: 2rem;
	height: 2rem;
	padding: 0 .25rem;
	line-height: 2rem;
	text-align: center;
	border-radius: 5px;
	font-weight: 600;
}
.stamp-md {
	min-width: 2.5rem;
	height: 2.5rem;
	line-height: 2.5rem;
}
.stamp-lg {
	min-width: 3rem;
	height: 3rem;
	line-height: 3rem;
}
.chat {
	outline: 0;
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: end;
	justify-content: flex-end;
	min-height: 100%;
	list-style: none;
	background: 0 0;
	margin: 0;
	padding: 0 0 0;
}
.chat-line {
	padding: 0;
	text-align: right;
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
	+.chat-line {
		padding-top: 1rem;
	}
}
.chat-message {
	position: relative;
	display: inline-block;
	background-color: $color-blue;
	color: #fff;
	font-size: .875rem;
	padding: .375rem .5rem;
	border-radius: 5px;
	white-space: normal;
	text-align: left;
	margin: 0 .5rem 0 2.5rem;
	line-height: 1.4;
	> :last-child {
		margin-bottom: 0 !important;
	}
	&:after {
		content: "";
		position: absolute;
		right: -5px;
		top: 7px;
		border-bottom: 6px solid transparent;
		border-left: 6px solid $color-blue;
		border-top: 6px solid transparent;
	}
	img {
		max-width: 100%;
	}
	p {
		margin-bottom: 1em;
	}
}
.chat-line-friend {
	-ms-flex-direction: row;
	flex-direction: row;
	+.chat-line-friend {
		margin-top: -.5rem;
		.chat-author {
			visibility: hidden;
		}
		.chat-message:after {
			display: none;
		}
	}
	.chat-message {
		background-color: $border-color;
		color: #3d4e67;
		margin-left: .5rem;
		margin-right: 2.5rem;
		&:after {
			right: auto;
			left: -5px;
			border-left-width: 0;
			border-right: 5px solid $border-color;
		}
	}
}
.example {
	padding: 1.5rem;
	border: 1px solid $border-color;
	border-radius: 3px 3px 0 0;
	font-size: .9375rem;
}
.example-bg {
	background: $border-color;
}
.example+.highlight {
	border-top: none;
	margin-top: 0;
	border-radius: 0 0 3px 3px;
}
.highlight {
	margin: 1rem 0 2rem;
	border: 1px solid $border-color;
	border-radius: 5px;
	font-size: .9375rem;
	max-height: 40rem;
	overflow: auto;
	background: $border-color;
	pre {
		margin-bottom: 0;
		background-color: rgba(70, 127, 207, 0.1);
	}
}
.example-column {
	margin: 0 auto;
	>.card:last-of-type {
		margin-bottom: 0;
	}
}
.example-column-1 {
	max-width: 20rem;
}
.example-column-2 {
	max-width: 40rem;
}
.timeline {
	position: relative;
	margin: 0 0 2rem;
	padding: 0;
	list-style: none;
	&:before {
		background-color: $border-color;
		position: absolute;
		display: block;
		content: '';
		height: 100%;
		top: 0;
		bottom: 0;
		left: 4px;
	}
}
.timeline-item {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	padding-left: 2rem;
	margin: .5rem 0;
	&:first-child:before, &:last-child:before {
		content: '';
		position: absolute;
		background: #fff;
		width: 1px;
		left: .25rem;
	}
	&:first-child {
		margin-top: 0;
		&:before {
			top: 0;
			height: .5rem;
		}
	}
	&:last-child {
		margin-bottom: 0;
		&:before {
			top: .5rem;
			bottom: 0;
		}
	}
}
.timeline-badge {
	position: absolute;
	display: block;
	width: .4375rem;
	height: .4375rem;
	left: 1px;
	top: .5rem;
	border-radius: 100%;
	border: 1px solid #fff;
	background: #adb5bd;
}
.timeline-time {
	white-space: nowrap;
	margin-left: auto;
	color: #cecde0;
	font-size: 87.5%;
}
svg {
	-ms-touch-action: none;
	touch-action: none;
}
.form-group.has-feedback span {
	display: block !important;
}
.ui-widget-content {
	padding: 0 !important;
}
.model-wrapper-demo {
	padding: 50px 0;
	background: rgba(70, 127, 207, 0.1);
}
.timeline__item:after {
	background: #fff !important;
}
.timeline--horizontal .timeline-divider {
	background: #dffbff !important;
}
.timeline__content {
	background-color: #fff;
	border-radius: 10px;
	display: block;
	padding: 1.25rem;
	position: relative;
}
#back-to-top {
	color: #fff;
	position: fixed;
	bottom: 80px;
	right: 20px;
	z-index: 99;
	display: none;
	text-align: center;
	border-radius: 50px;
	-moz-border-radius: 50px;
	-webkit-border-radius: 50px;
	-o-border-radius: 2px;
	z-index: 10000;
	height: 50px;
	width: 50px;
	line-height: 59px;
	background-repeat: no-repeat;
	background-position: center;
	transition: background-color .1s linear;
	-moz-transition: background-color .1s linear;
	-webkit-transition: background-color .1s linear;
	-o-transition: background-color .1s linear;
	i {
		font-size: 22px;
	}
}
#chat-message {
	color: #fff;
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 99;
	display: none;
	text-align: center;
	border-radius: 50px;
	-moz-border-radius: 50px;
	-webkit-border-radius: 50px;
	-o-border-radius: 2px;
	z-index: 10000;
	height: 50px;
	width: 50px;
	background-repeat: no-repeat;
	background-position: center;
	transition: background-color .1s linear;
	-moz-transition: background-color .1s linear;
	-webkit-transition: background-color .1s linear;
	-o-transition: background-color .1s linear;
	i {
		padding-top: 15px;
		font-size: 16px;
	}
	&:hover {
		background: #fff;
		color: #4c8aec;
		border: 2px solid #0f75ff;
	}
}
.tabs-menu ul li {
	a {
		padding: 8px 20px 8px 20px;
		display: block;
		background: #ffffff;
		margin-right: 5px;
		border-radius: 5px;
		border: 1px solid $border-color;
		font-weight: 500;
	}
	.active {
		color: #fff;
		border-radius: 5px;
		display: block;
	}
}
.tabs-menu1 ul li {
	a {
		padding: 10px 20px 11px 20px;
		display: block;
		background: #ffffff;
		border: 1px solid $border-color;
		border-radius: 5px;
		margin-right: 2px;
	}
	.active {
		border-radius: 5px;
		background: #fff;
	}
}
.wideget-user-tab .tabs-menu1 ul li .active {
	border: 0;
	border-radius: 5px 5px 0 0;
}
.tabs-menu-body {
	padding: 15px;
	border: 1px solid $border-color;
	p:last-child {
		margin-bottom: 0;
	}
}
.tab-menu-heading {
	padding: 20px;
	border: 1px solid $border-color;
	border-bottom: 0;
}
.tab_wrapper .content_wrapper .tab_content.active p:last-child {
	margin-bottom: 0;
}
.item-card {
	.cardbody {
		position: relative;
		padding: 30px 20px;
		border-radius: 0 0 .2rem .2rem;
	}
	.cardtitle {
		span {
			display: block;
			font-size: .75rem;
		}
		a {
			color: #1f252d;
			font-weight: 500;
			text-decoration: none;
			font-size: 16px;
		}
	}
	.cardprice {
		position: absolute;
		top: 30px;
		right: 15px;
		span {
			&.type--strikethrough {
				opacity: .7;
				text-decoration: line-through;
				font-size: 14px;
			}
			display: block;
			color: #1f252d;
			font-size: 20px;
			font-weight: 700;
		}
	}
}
.features {
	overflow: hidden;
	h2 {
		font-weight: 600;
		margin-bottom: 12px;
		text-align: center;
		font-size: 2.2em;
	}
	h3 {
		font-size: 20px;
		font-weight: 500;
	}
	span {
		color: #43414e;
		display: block;
		font-weight: 400;
		text-align: center;
	}
}
.feature {
	.feature-svg {
		width: 25%;
	}
	.feature-svg3 {
		width: 12%;
	}
}
.col-sm-2 .feature {
	padding: 0;
	border: 0;
	box-shadow: none;
}
.feature {
	.fea-icon {
		position: relative;
		display: block;
		width: 4rem;
		height: 4rem;
		vertical-align: middle;
		border-radius: 50%;
		color: #fff;
		line-height: 4rem;
		margin: 0 auto;
		text-align: center;
	}
	p {
		margin-bottom: 0;
	}
}
.box-shadow-0 {
	box-shadow: none !important;
}
#messages-main {
	position: relative;
	margin: 0 auto;
	&:after, &:before {
		content: " ";
		display: table;
	}
	.ms-menu {
		position: absolute;
		left: 0;
		top: 0;
		border-right: 1px solid $border-color;
		padding-bottom: 50px;
		height: 100%;
		width: 240px;
		background: #fff;
		&.toggled {
			display: block;
		}
	}
	.ms-body {
		overflow: hidden;
	}
	.ms-user>div {
		overflow: hidden;
		padding: 3px 5px 0 15px;
		font-size: 11px;
	}
	#ms-compose {
		position: fixed;
		bottom: 120px;
		z-index: 1;
		right: 30px;
		box-shadow: 0 0 4px rgba(49, 45, 101, 0.14), 0 4px 8px rgba(49, 45, 101, 0.28);
	}
}
#ms-menu-trigger {
	i {
		font-size: 21px;
	}
	&.toggled i:before {
		content: '\f2ea';
	}
}
.fc-toolbar:before, .login-content:after {
	content: "";
}
.message-feed {
	padding: 10px;
}
#footer, .fc-toolbar .ui-button, .fileinput .thumbnail {
	text-align: center;
}
.four-zero {
	text-align: center;
	footer>a {
		text-align: center;
	}
}
.ie-warning, .login-content, .login-navigation {
	text-align: center;
}
.pt-inner {
	text-align: center;
	.pti-footer>a {
		text-align: center;
	}
}
.message-feed.right {
	>.pull-right {
		margin-left: 15px;
	}
	.mf-content {
		background: $border-color;
	}
}
.mf-content {
	padding: 9px;
	border-radius: 2px;
	display: inline-block;
	max-width: 80%;
	position: relative;
}
.message-feed {
	&:not(.right) .mf-content:before {
		content: '';
		display: block;
		position: absolute;
		border: 10px solid transparent;
		left: -20px;
		top: 11px;
	}
	&.right .mf-content:before {
		content: '';
		display: block;
		position: absolute;
		border: 10px solid transparent;
		border-left-color: $border-color;
		right: -20px;
		top: 11px;
	}
}
.mf-date {
	display: block;
	margin-top: 7px;
	>i {
		font-size: 14px;
		line-height: 100%;
		position: relative;
		top: 1px;
	}
}
.msb-reply {
	position: relative;
	margin-top: 3px;
	background: #fff;
	padding: 10px;
	border-radius: 0 7px 7px 7px;
}
.four-zero, .lc-block {
	box-shadow: 0 1px 11px rgba(49, 45, 101, 0.27);
}
.msb-reply {
	textarea {
		width: 100%;
		font-size: 13px;
		border: 0;
		padding: 10px 15px;
		resize: none;
		background: 0 0;
	}
	button {
		top: 0;
		right: 0;
		border: 0;
		height: 100%;
		width: 60px;
		font-size: 20px;
		color: #fff;
		border-radius: 0 3px 3px 0;
		line-height: 36px;
	}
}
.img-avatar {
	height: 37px;
	border-radius: 2px;
	width: 37px;
}
.list-group.lg-alt .list-group-item {
	border: 0;
}
.p-15 {
	padding: 15px !important;
}
.action-header {
	position: relative;
	background: #f8f8f8;
	padding: 15px 13px 15px 17px;
}
.ah-actions {
	z-index: 3;
	float: right;
	margin-top: 7px;
	position: relative;
}
.actions {
	list-style: none;
	padding: 0;
	margin: 0;
	>li {
		display: inline-block;
	}
	&:not(.a-alt)>li>a>i {
		color: #939393;
	}
	>li>a {
		>i {
			font-size: 20px;
		}
		display: block;
		padding: 0 10px;
	}
}
.ms-body {
	background: #fff;
	border-radius: 7px;
}
#ms-menu-trigger {
	user-select: none;
	position: absolute;
	left: 0;
	top: 0;
	width: 50px;
	height: 100%;
	padding-right: 10px;
	padding-top: 19px;
	cursor: pointer;
	text-align: right;
}
.message-feed.right {
	text-align: right;
}
#ms-menu-trigger, .toggle-switch {
	-webkit-user-select: none;
	-moz-user-select: none;
}
.message-feed {
	&.media .media-body, &.right .media-body {
		overflow: visible;
	}
}
.amcharts-chart-div a, .canvasjs-chart-container a {
	display: none !important;
}
.rotation {
	-webkit-animation: rotation 2s infinite linear;
}
@-webkit-keyframes rotation {
	from {
		-webkit-transform: rotate(0);
	}
	to {
		-webkit-transform: rotate(359deg);
	}
}
.amChartsLegend.amcharts-legend-div {
	display: none;
}
.input-icon .form-group.is-empty {
	.form-control {
		margin-bottom: 0;
	}
	margin-bottom: 0;
}
.wizard-card {
	.moving-tab {
		margin-top: 5px;
	}
	&.form-group .form-control {
		background: linear-gradient(#c4c4c4, #c4c4c4);
	}
	.choice .icon {
		border: 4px solid $border-color !important;
	}
}
.wizard-container .wizard-navigation {
	background: $border-color;
}
.richText {
	.richText-toolbar {
		ul {
			li a {
				border-right: $border-color solid 1px;
			}
			background: #f6f7fb;
			border-bottom: $border-color solid 1px;
		}
		background: #f6f7fb;
		border-top: $border-color solid 0px;
		border-bottom: $border-color solid 0px;
	}
	border: $border-color solid 1px;
	background-color: $border-color;
}
.cal1 {
	.clndr .clndr-table {
		.header-days .header-day {
			border-left: 1px solid rgba(70, 127, 207, 0.1);
			border-top: 1px solid $border-color;
		}
		tr {
			.adjacent-month, .empty, .my-adjacent-month, .my-empty {
				border-left: 1px solid #79c2b2;
				border-top: 1px solid #79c2b2;
				background: $border-color;
			}
			.day {
				&.event, &.my-event {
					background: #f6f7fb;
				}
				&.event:hover, &.my-event:hover {
					background: #15e0fd;
				}
				border-left: 1px solid $border-color;
				border-top: 1px solid $border-color;
				&:last-child {
					border-right: 1px solid $border-color;
				}
				&:hover {
					background: #e6f2fe;
				}
			}
		}
	}
	font-size: 14px;
	border: 1px solid $border-color;
}
.fc-unthemed {
	.fc-content, .fc-divider, .fc-list-heading td, .fc-list-view, .fc-popover, .fc-row, tbody, td, th, thead {
		border-color: rgba(227, 237, 252, 1);
	}
}
.fc-event {
	border: 1px solid $border-color;
}
.fc-unthemed {
	.fc-divider, .fc-list-heading td, .fc-popover .fc-header {
		background: $border-color;
	}
}
.fc-toolbar {
	.fc-state-active, .ui-state-active {
		background: #09acca;
	}
}
.fc-today-button fc-button fc-state-default fc-corner-left fc-corner-right fc-state-disabled:focus {
	border: none !important;
	box-shadow: none !important;
}
.fc-unthemed .fc-list-item:hover td {
	background-color: $border-color;
}
.conv-form-wrapper div#messages div.message.to {
	background: $body-color;
}
#sidebar {
	.accordion-toggle i {
		color: #fff;
		height: 30px;
		line-height: 30px;
		text-align: center;
		vertical-align: middle;
		width: 30px !important;
		border-radius: 50%;
	}
	ul li.active>a:hover {
		background: rgba(49, 45, 101, 0.01);
		border-radius: 7px;
	}
}
.overflow-hidden {
	overflow: hidden;
}
/*-------- Custom CSS -------*/
