@charset "UTF-8";

:root{
	--body-color: #f5f4fb;
	--text-color: #333063;
	--border-color: #efecfb;
	--border-dark-color: #efecfb;
	--color-primary: #7167ff;
	--btn-hover-primary:#ed3025;
	--color-secondary: #f96768;
	--btn-hover-secondary:#e44f50;
	--color-dark-purple: #312d65;
	--btn-hover-dark-purple:#312d65;
	--color-success: #3eb750;
	--btn-hover-success:#349e44;
	--color-info: #23bcb9;
	--btn-hover-info:#23bcb9;
	--color-warning: #ffb609;
	--btn-hover-warning:#ffb609;
	--color-danger: #f96768;
	--btn-hover-danger:#e44f50;
	--color-blue: #467fcf;
	--btn-hover-blue:#316cbe;
	--color-azure: #00d6e6;
	--btn-hover-azure:#316cbe;
	--color-indigo: #6574cd;
	--btn-hover-indigo:#0ea9b5;
	--color-purple: #604dd8;
	--btn-hover-purple:#923ce6;
	--color-pink: #ec296b;
	--btn-hover-pink:#e61470;
	--color-red: #fe6567;
	--btn-hover-red:#b3160b;
	--color-orange: #e67605;
	--btn-hover-orange:#fd811e;
	--color-yellow: #ffb609;
	--btn-hover-yellow:#fa3;
	--color-lime: #63e600;
	--btn-hover-lime:#69b829;
	--color-green: #3eb750;
	--btn-hover-green:#3adfab;
	--color-teal: #05e6e6;
	--btn-hover-teal:#05fafa;
	--color-cyan: #17a2b8;
	--btn-hover-cyan:#138496;
	--color-gray: #868e96;
	--btn-hover-gray:#727b84;
	--color-gray-dark: #343a40;
	--btn-hover-gray-dark:#23272b;	
}

/*--------Booistrap Framewrok-------*/

*, ::after, ::before {
	box-sizing: border-box;
}
@-ms-viewport {
	width: device-width;
}
body {
	margin: 0;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.5;
	color: $text-color;
	text-align: left;
	background-color: $body-color;
	font-family: 'Roboto', sans-serif;
}
[type=number] {
	&::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
		height: auto;
	}
}
[type=search] {
	outline-offset: -2px;
	-webkit-appearance: none;
	&::-webkit-search-cancel-button, &::-webkit-search-decoration {
		-webkit-appearance: none;
	}
}
a {
	color: #3f3e50;
	text-decoration: none;
	background-color: transparent;
	-webkit-text-decoration-skip: objects;
	&:hover {
		text-decoration: none;
	}
	&:not([href]):not([tabindex]) {
		color: inherit;
		text-decoration: none;
		&:focus, &:hover {
			color: inherit;
			text-decoration: none;
		}
		&:focus {
			outline: 0;
			text-decoration: none;
		}
	}
}
.h1, h1 {
	font-size: 2.5rem;
	font-weight: 400;
}
.h2, h2 {
	font-size: 1.75rem;
}
.h3, h3 {
	font-size: 1.5rem;
}
.h4, h4 {
	font-size: 1.125rem;
}
.h5, h5 {
	font-size: 1rem;
}
.h6, h6 {
	font-size: .875rem;
}
.lead {
	font-size: 1.171875rem;
	font-weight: 300;
	line-height: 1.4;
}
.display-1 {
	font-size: 10.5rem;
	font-weight: 400;
	line-height: 1.1;
}
.display-2 {
	font-size: 4rem;
	font-weight: 300;
	line-height: 1.1;
}
.display-3 {
	font-size: 3.5rem;
	font-weight: 300;
	line-height: 1.1;
}
.display-4 {
	font-size: 3rem;
	font-weight: 300;
	line-height: 1.1;
}
.display-5 {
	font-size: 2.4rem;
	font-weight: 300;
	line-height: 1.1;
}
.display-6 {
	font-size: 2.3rem;
	font-weight: 300;
	line-height: 1.1;
}
.top-0 {
	top: 0 !important;
}
hr {
	margin-top: 1rem;
	margin-bottom: 1rem;
	border: 0;
	border-top: 1px solid #e3e3e4;
	box-sizing: content-box;
	height: 0;
	overflow: visible;
}
.blockquote-footer {
	display: block;
	font-size: 80%;
	color: #868e96;
	&::before {
		content: "\2014 \00A0";
	}
}
.cover-image {
	background-size: cover !important;
	position: relative;
	width:100%;
}
.img-thumbnail {
	padding: .25rem;
	background-color: #fff;
	border: 1px solid $border-color;
	border-radius: 5px;
	max-width: 100%;
	height: auto;
}
.fs-10 {
	font-size: 10px !important;
}
.fs-12 {
	font-size: 12px !important;
}
.fs-13 {
	font-size: 13px !important;
}
.fs-14 {
	font-size: 14px !important;
}
.fs-15 {
	font-size: 15px !important;
}
.fs-16 {
	font-size: 16px !important;
}
.fs-18 {
	font-size: 18px !important;
}
.fs-20 {
	font-size: 20px !important;
}
.fs-22 {
	font-size: 22px !important;
}
.fs-24 {
	font-size: 24px !important;
}
.fs-26 {
	font-size: 26px !important;
}
.fs-28 {
	font-size: 28px !important;
}
.fs-30 {
	font-size: 30px !important;
}
.fs-32 {
	font-size: 32px !important;
}
.fs-35 {
	font-size: 35px !important;
}
.fs-38 {
	font-size: 38px !important;
}
.fs-40 {
	font-size: 40px !important;
}
.fs-50 {
	font-size: 50px !important;
}
.fs-60 {
	font-size: 60px !important;
}
.fs-70 {
	font-size: 70px !important;
}
.fs-80 {
	font-size: 80px !important;
}
.fs-90 {
	font-size: 90px !important;
}
.fs-100 {
	font-size: 100px !important;
}
blockquote {
	color: #3f3e50;
	padding-left: 2rem;
	border-left: 3px solid rgba(227, 237, 252, 0.5);
	margin-bottom: 1rem;
	font-size: 1.171875rem;
}
.blockquote-reverse {
	padding-right: 15px;
	padding-left: 0;
	text-align: right;
	border-right: 3px solid rgba(227, 237, 252, 0.5);
	border-left: 0;
}
blockquote {
	&.float-right {
		padding-right: 15px;
		padding-left: 0;
		text-align: right;
		border-right: 3px solid rgba(227, 237, 252, 0.5);
		border-left: 0;
	}
	p {
		margin-bottom: 1rem;
	}
	cite {
		display: block;
		text-align: right;
		&:before {
			content: '— ';
		}
	}
}
pre {
	code {
		padding: 0;
		border-radius: 0;
		border: none;
		background: 0 0;
		font-size: inherit;
		color: inherit;
		word-break: normal;
	}
	color: #343a40;
	padding: 1rem;
	overflow: auto;
	font-size: 85%;
	line-height: 1.45;
	background-color: #f8fafc;
	border-radius: 5px;
	-moz-tab-size: 4;
	-o-tab-size: 4;
	tab-size: 4;
	text-shadow: 0 1px #fff;
	-webkit-hyphens: none;
	-moz-hyphens: none;
	-ms-hyphens: none;
	hyphens: none;
}
.text-wrap {
	font-size: 14px;
	line-height: 1.66;
	> {
		:first-child {
			margin-top: 0;
		}
		:last-child {
			margin-bottom: 0;
		}
		h1, h2, h3, h4, h5, h6 {
			margin-top: 1em;
		}
	}
}
.section-nav {
	background-color: #f8f9fa;
	margin: 1rem 0;
	padding: .5rem 1rem;
	border: 1px solid $border-color;
	border-radius: 5px;
	list-style: none;
	&:before {
		content: 'Table of contents:';
		display: block;
		font-weight: 600;
	}
}
@media print {
	.container {
		max-width: none;
	}
}
.row-cards> {
	.col, [class*=col-] {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
.row-deck> {
	.col, [class*=col-] {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-align: stretch;
		align-items: stretch;
	}
	.col .card, [class*=col-] .card {
		-ms-flex: 1 1 auto;
		flex: 1 1 auto;
	}
}
.col-text {
	max-width: 48rem;
}
.col-login {
	max-width: 24rem;
}
.gutters-0 {
	margin-right: 0;
	margin-left: 0;
	> {
		.col, [class*=col-] {
			padding-right: 0;
			padding-left: 0;
		}
	}
	.card {
		margin-bottom: 0;
	}
}
.gutters-xs {
	margin-right: -.25rem;
	margin-left: -.25rem;
	> {
		.col, [class*=col-] {
			padding-right: .25rem;
			padding-left: .25rem;
		}
	}
	.card {
		margin-bottom: .5rem;
	}
}
.gutters-sm {
	margin-right: -.5rem;
	margin-left: -.5rem;
	> {
		.col, [class*=col-] {
			padding-right: .5rem;
			padding-left: .5rem;
		}
	}
	.card {
		margin-bottom: 1rem;
	}
}
.gutters-lg {
	margin-right: -1rem;
	margin-left: -1rem;
	> {
		.col, [class*=col-] {
			padding-right: 1rem;
			padding-left: 1rem;
		}
	}
	.card {
		margin-bottom: 2rem;
	}
}
.gutters-xl {
	margin-right: -1.5rem;
	margin-left: -1.5rem;
	> {
		.col, [class*=col-] {
			padding-right: 1.5rem;
			padding-left: 1.5rem;
		}
	}
	.card {
		margin-bottom: 3rem;
	}
}
/*-------- Bootstrap Frame Work -------*/
