/* Opacity */

.op-1 {
	opacity: 0.1;
}
.op-2 {
	opacity: 0.2;
}
.op-3 {
	opacity: 0.3;
}
.op-4 {
	opacity: 0.4;
}
.op-5 {
	opacity: 0.5;
}
.op-6 {
	opacity: 0.6;
}
.op-7 {
	opacity: 0.7;
}
.op-8 {
	opacity: 0.8;
}
.op-9 {
	opacity: 0.9;
}
.op-10 {
	opacity: 1;
}
