/*-------- Accordion -------*/
.panel-group .panel {
	border-radius: 0;
	box-shadow: none;
	border-color: $border-color;
}
.panel-default>.panel-heading {
	padding: 0;
	border-radius: 0;
	background-color: $border-color;
	border-color: $border-color;
}
.panel-title {
	font-size: 14px;
	margin-bottom: 0;
	>a {
		display: block;
		padding: 15px;
		text-decoration: none;
	}
}
.more-less {
	float: right;
	color: #212121;
}
.panel-default>.panel-heading+.panel-collapse>.panel-body {
	border: 1px solid $border-color;
}
a {
	&:active, &:focus, &:hover {
		outline: 0;
		text-decoration: none;
	}
}
.panel1 {
	border-width: 0 0 1px 0;
	border-style: solid;
	border-color: #fff;
	background: 0 0;
	box-shadow: none;
	&:last-child {
		border-bottom: none;
	}
}
.panel-group1 {
	>.panel1:first-child .panel-heading1 {
		border-radius: 4px 4px 0 0;
	}
	.panel1 {
		border-radius: 0;
	}
}
.card-title1 {
	font-size: 14px;
	margin-bottom: 0;
}
.panel-group1 .panel1+.panel1 {
	margin-top: 0;
}
.panel-heading1 {
	background-color: #fff;
	border-radius: 0;
	border: none;
	color: #3f3e50;
	padding: 0;
}
.panel-group1 .panel-body {
	border-top: 1px solid $border-color;
}
.card-title1 a {
	display: block;
	padding: 1.5rem 1.5rem;
	position: relative;
	font-size: 16px;
	font-weight: 500;
	&.collapsed {
		color: #3f3e50;
	}
}
.panel-body1 {
	background: #fff;
	padding: 10px;
}
.panel1:last-child {
	.panel-body1 {
		border-radius: 0 0 4px 4px;
	}
	.panel-heading1 {
		border-radius: 0 0 4px 4px;
		transition: border-radius .3s linear .2s;
		&.active {
			border-radius: 0;
			transition: border-radius linear 0s;
		}
	}
}
.panel-title a.accordion-toggle {
	&:before {
		content: "\f068";
		padding: 0 10px 0 0;
		color: #fff;
		font-family: FontAwesome;
		float: right;
	}
	&.collapsed:before {
		content: "\f067";
		padding: 0 10px 0 0;
		color: #fff;
		font-family: FontAwesome;
		float: right;
	}
}
.panel-heading1 {
	a {
		&.collapsed:before {
			content: "\e9af";
			position: absolute;
			font-family: feather !important;
			right: 24px;
			top: 24px;
			font-size: 18px;
			transition: all .5s;
			transform: scale(1);
		}
		&:before {
			content: "\e994";
			position: absolute;
			font-family: feather !important;
			right: 24px;
			top: 24px;
			font-size: 18px;
			transition: all .5s;
			transform: scale(1);
		}
	}
	&.active a:before {
		content: ' ';
		transition: all .5s;
		transform: scale(0);
	}
}
.demo-accordion p:last-child, .panel-group1 p:last-child {
	margin-bottom: 0;
}
#bs-collapse .panel-heading1 {
	a:after {
		content: "\f01a";
		font-size: 24px;
		position: absolute;
		font-family: FontAwesome;
		right: 5px;
		top: 10px;
		transform: scale(0);
		transition: all .5s;
	}
	&.active a:after {
		content: "\f01a";
		transform: scale(1);
		transition: all .5s;
	}
}
#accordion .panel-heading1 {
	a:before {
		content: "\f01a";
		font-size: 24px;
		position: absolute;
		font-family: FontAwesome;
		right: 5px;
		top: 10px;
		transform: rotate(180deg);
		transition: all .5s;
	}
	&.active a:before {
		transform: rotate(0);
		transition: all .5s;
	}
}
.accordion .card {
	&:not(:first-of-type) {
		&:not(:last-of-type) {
			border-bottom: 0;
			border-radius: 0;
		}
		.card-header:first-child {
			border-radius: 0;
		}
	}
	&:first-of-type {
		border-bottom: 0;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	}
	&:last-of-type {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
}
/*-------- Accordion -------*/
