

.m-3 {
	margin: 0.75rem !important;
}
.mt-3, .my-3 {
	margin-top: 0.75rem !important;
}
.mr-3, .mx-3 {
	margin-right: 0.75rem !important;
}
.mb-3, .my-3 {
	margin-bottom: 0.75rem !important;
}
.ml-3, .mx-3 {
	margin-left: 0.75rem !important;
}
.m-4 {
	margin: 1rem !important;
}
.mt-4, .my-4 {
	margin-top: 1rem !important;
}
.mr-4, .mx-4 {
	margin-right: 1rem !important;
}
.mb-4, .my-4 {
	margin-bottom: 1rem !important;
}
.ml-4, .mx-4 {
	margin-left: 1rem !important;
}
.m-5 {
	margin: 1.5rem !important;
}
.mt-5, .my-5 {
	margin-top: 1.5rem !important;
}
.mr-5, .mx-5 {
	margin-right: 1.5rem !important;
}
.mb-5, .my-5 {
	margin-bottom: 1.5rem !important;
}
.ml-5, .mx-5 {
	margin-left: 1.5rem !important;
}
.m-6 {
	margin: 2rem !important;
}
.mt-6, .my-6 {
	margin-top: 2rem !important;
}
.mr-6, .mx-6 {
	margin-right: 2rem !important;
}
.mb-6, .my-6 {
	margin-bottom: 2rem !important;
}
.ml-6, .mx-6 {
	margin-left: 2rem !important;
}
.m-7 {
	margin: 3rem !important;
}
.mt-7, .my-7 {
	margin-top: 3rem !important;
}
.mr-7, .mx-7 {
	margin-right: 3rem !important;
}
.mb-7, .my-7 {
	margin-bottom: 3rem !important;
}
.ml-7, .mx-7 {
	margin-left: 3rem !important;
}
.m-8 {
	margin: 4rem !important;
}
.mt-8, .my-8 {
	margin-top: 4rem !important;
}
.mr-8, .mx-8 {
	margin-right: 4rem !important;
}
.mb-8, .my-8 {
	margin-bottom: 4rem !important;
}
.ml-8, .mx-8 {
	margin-left: 4rem !important;
}
.m-9 {
	margin: 6rem !important;
}
.mt-9, .my-9 {
	margin-top: 6rem !important;
}
.mr-9, .mx-9 {
	margin-right: 6rem !important;
}
.mb-9, .my-9 {
	margin-bottom: 6rem !important;
}
.ml-9, .mx-9 {
	margin-left: 6rem !important;
}
.mt-10 {
	margin-top: 9.95rem !important;
}
@media (min-width: 576px) {
	.m-sm-3 {
		margin: 0.75rem !important;
	}
	.mt-sm-3, .my-sm-3 {
		margin-top: 0.75rem !important;
	}
	.mr-sm-3, .mx-sm-3 {
		margin-right: 0.75rem !important;
	}
	.mb-sm-3, .my-sm-3 {
		margin-bottom: 0.75rem !important;
	}
	.ml-sm-3, .mx-sm-3 {
		margin-left: 0.75rem !important;
	}
	.m-sm-4 {
		margin: 1rem !important;
	}
	.mt-sm-4, .my-sm-4 {
		margin-top: 1rem !important;
	}
	.mr-sm-4, .mx-sm-4 {
		margin-right: 1rem !important;
	}
	.mb-sm-4, .my-sm-4 {
		margin-bottom: 1rem !important;
	}
	.ml-sm-4, .mx-sm-4 {
		margin-left: 1rem !important;
	}
	.m-sm-5 {
		margin: 1.5rem !important;
	}
	.mt-sm-5, .my-sm-5 {
		margin-top: 1.5rem !important;
	}
	.mr-sm-5, .mx-sm-5 {
		margin-right: 1.5rem !important;
	}
	.mb-sm-5, .my-sm-5 {
		margin-bottom: 1.5rem !important;
	}
	.ml-sm-5, .mx-sm-5 {
		margin-left: 1.5rem !important;
	}
	.m-sm-6 {
		margin: 2rem !important;
	}
	.mt-sm-6, .my-sm-6 {
		margin-top: 2rem !important;
	}
	.mr-sm-6, .mx-sm-6 {
		margin-right: 2rem !important;
	}
	.mb-sm-6, .my-sm-6 {
		margin-bottom: 2rem !important;
	}
	.ml-sm-6, .mx-sm-6 {
		margin-left: 2rem !important;
	}
	.m-sm-7 {
		margin: 3rem !important;
	}
	.mt-sm-7, .my-sm-7 {
		margin-top: 3rem !important;
	}
	.mr-sm-7, .mx-sm-7 {
		margin-right: 3rem !important;
	}
	.mb-sm-7, .my-sm-7 {
		margin-bottom: 3rem !important;
	}
	.ml-sm-7, .mx-sm-7 {
		margin-left: 3rem !important;
	}
	.m-sm-8 {
		margin: 4rem !important;
	}
	.mt-sm-8, .my-sm-8 {
		margin-top: 4rem !important;
	}
	.mr-sm-8, .mx-sm-8 {
		margin-right: 4rem !important;
	}
	.mb-sm-8, .my-sm-8 {
		margin-bottom: 4rem !important;
	}
	.ml-sm-8, .mx-sm-8 {
		margin-left: 4rem !important;
	}
	.m-sm-9 {
		margin: 6rem !important;
	}
	.mt-sm-9, .my-sm-9 {
		margin-top: 6rem !important;
	}
	.mr-sm-9, .mx-sm-9 {
		margin-right: 6rem !important;
	}
	.mb-sm-9, .my-sm-9 {
		margin-bottom: 6rem !important;
	}
	.ml-sm-9, .mx-sm-9 {
		margin-left: 6rem !important;
	}
}
@media (min-width: 768px) {
	.m-md-3 {
		margin: 0.75rem !important;
	}
	.mt-md-3, .my-md-3 {
		margin-top: 0.75rem !important;
	}
	.mr-md-3, .mx-md-3 {
		margin-right: 0.75rem !important;
	}
	.mb-md-3, .my-md-3 {
		margin-bottom: 0.75rem !important;
	}
	.ml-md-3, .mx-md-3 {
		margin-left: 0.75rem !important;
	}
	.m-md-4 {
		margin: 1rem !important;
	}
	.mt-md-4, .my-md-4 {
		margin-top: 1rem !important;
	}
	.mr-md-4, .mx-md-4 {
		margin-right: 1rem !important;
	}
	.mb-md-4, .my-md-4 {
		margin-bottom: 1rem !important;
	}
	.ml-md-4, .mx-md-4 {
		margin-left: 1rem !important;
	}
	.m-md-5 {
		margin: 1.5rem !important;
	}
	.mt-md-5, .my-md-5 {
		margin-top: 1.5rem !important;
	}
	.mr-md-5, .mx-md-5 {
		margin-right: 1.5rem !important;
	}
	.mb-md-5, .my-md-5 {
		margin-bottom: 1.5rem !important;
	}
	.ml-md-5, .mx-md-5 {
		margin-left: 1.5rem !important;
	}
	.m-md-6 {
		margin: 2rem !important;
	}
	.mt-md-6, .my-md-6 {
		margin-top: 2rem !important;
	}
	.mr-md-6, .mx-md-6 {
		margin-right: 2rem !important;
	}
	.mb-md-6, .my-md-6 {
		margin-bottom: 2rem !important;
	}
	.ml-md-6, .mx-md-6 {
		margin-left: 2rem !important;
	}
	.m-md-7 {
		margin: 3rem !important;
	}
	.mt-md-7, .my-md-7 {
		margin-top: 3rem !important;
	}
	.mr-md-7, .mx-md-7 {
		margin-right: 3rem !important;
	}
	.mb-md-7, .my-md-7 {
		margin-bottom: 3rem !important;
	}
	.ml-md-7, .mx-md-7 {
		margin-left: 3rem !important;
	}
	.m-md-8 {
		margin: 4rem !important;
	}
	.mt-md-8, .my-md-8 {
		margin-top: 4rem !important;
	}
	.mr-md-8, .mx-md-8 {
		margin-right: 4rem !important;
	}
	.mb-md-8, .my-md-8 {
		margin-bottom: 4rem !important;
	}
	.ml-md-8, .mx-md-8 {
		margin-left: 4rem !important;
	}
	.m-md-9 {
		margin: 6rem !important;
	}
	.mt-md-9, .my-md-9 {
		margin-top: 6rem !important;
	}
	.mr-md-9, .mx-md-9 {
		margin-right: 6rem !important;
	}
	.mb-md-9, .my-md-9 {
		margin-bottom: 6rem !important;
	}
	.ml-md-9, .mx-md-9 {
		margin-left: 6rem !important;
	}
}
@media (min-width: 992px) {
	.m-lg-3 {
		margin: 0.75rem !important;
	}
	.mt-lg-3, .my-lg-3 {
		margin-top: 0.75rem !important;
	}
	.mr-lg-3, .mx-lg-3 {
		margin-right: 0.75rem !important;
	}
	.mb-lg-3, .my-lg-3 {
		margin-bottom: 0.75rem !important;
	}
	.ml-lg-3, .mx-lg-3 {
		margin-left: 0.75rem !important;
	}
	.m-lg-4 {
		margin: 1rem !important;
	}
	.mt-lg-4, .my-lg-4 {
		margin-top: 1rem !important;
	}
	.mr-lg-4, .mx-lg-4 {
		margin-right: 1rem !important;
	}
	.mb-lg-4, .my-lg-4 {
		margin-bottom: 1rem !important;
	}
	.ml-lg-4, .mx-lg-4 {
		margin-left: 1rem !important;
	}
	.m-lg-5 {
		margin: 1.5rem !important;
	}
	.mt-lg-5, .my-lg-5 {
		margin-top: 1.5rem !important;
	}
	.mr-lg-5, .mx-lg-5 {
		margin-right: 1.5rem !important;
	}
	.mb-lg-5, .my-lg-5 {
		margin-bottom: 1.5rem !important;
	}
	.ml-lg-5, .mx-lg-5 {
		margin-left: 1.5rem !important;
	}
	.m-lg-6 {
		margin: 2rem !important;
	}
	.mt-lg-6, .my-lg-6 {
		margin-top: 2rem !important;
	}
	.mr-lg-6, .mx-lg-6 {
		margin-right: 2rem !important;
	}
	.mb-lg-6, .my-lg-6 {
		margin-bottom: 2rem !important;
	}
	.ml-lg-6, .mx-lg-6 {
		margin-left: 2rem !important;
	}
	.m-lg-7 {
		margin: 3rem !important;
	}
	.mt-lg-7, .my-lg-7 {
		margin-top: 3rem !important;
	}
	.mr-lg-7, .mx-lg-7 {
		margin-right: 3rem !important;
	}
	.mb-lg-7, .my-lg-7 {
		margin-bottom: 3rem !important;
	}
	.ml-lg-7, .mx-lg-7 {
		margin-left: 3rem !important;
	}
	.m-lg-8 {
		margin: 4rem !important;
	}
	.mt-lg-8, .my-lg-8 {
		margin-top: 4rem !important;
	}
	.mr-lg-8, .mx-lg-8 {
		margin-right: 4rem !important;
	}
	.mb-lg-8, .my-lg-8 {
		margin-bottom: 4rem !important;
	}
	.ml-lg-8, .mx-lg-8 {
		margin-left: 4rem !important;
	}
	.m-lg-9 {
		margin: 6rem !important;
	}
	.mt-lg-9, .my-lg-9 {
		margin-top: 6rem !important;
	}
	.mr-lg-9, .mx-lg-9 {
		margin-right: 6rem !important;
	}
	.mb-lg-9, .my-lg-9 {
		margin-bottom: 6rem !important;
	}
	.ml-lg-9, .mx-lg-9 {
		margin-left: 6rem !important;
	}
}
@media (min-width: 1200px) {
	.m-xl-3 {
		margin: 0.75rem !important;
	}
	.mt-xl-3, .my-xl-3 {
		margin-top: 0.75rem !important;
	}
	.mr-xl-3, .mx-xl-3 {
		margin-right: 0.75rem !important;
	}
	.mb-xl-3, .my-xl-3 {
		margin-bottom: 0.75rem !important;
	}
	.ml-xl-3, .mx-xl-3 {
		margin-left: 0.75rem !important;
	}
	.m-xl-4 {
		margin: 1rem !important;
	}
	.mt-xl-4, .my-xl-4 {
		margin-top: 1rem !important;
	}
	.mr-xl-4, .mx-xl-4 {
		margin-right: 1rem !important;
	}
	.mb-xl-4, .my-xl-4 {
		margin-bottom: 1rem !important;
	}
	.ml-xl-4, .mx-xl-4 {
		margin-left: 1rem !important;
	}
	.m-xl-5 {
		margin: 1.5rem !important;
	}
	.mt-xl-5, .my-xl-5 {
		margin-top: 1.5rem !important;
	}
	.mr-xl-5, .mx-xl-5 {
		margin-right: 1.5rem !important;
	}
	.mb-xl-5, .my-xl-5 {
		margin-bottom: 1.5rem !important;
	}
	.ml-xl-5, .mx-xl-5 {
		margin-left: 1.5rem !important;
	}
	.m-xl-6 {
		margin: 2rem !important;
	}
	.mt-xl-6, .my-xl-6 {
		margin-top: 2rem !important;
	}
	.mr-xl-6, .mx-xl-6 {
		margin-right: 2rem !important;
	}
	.mb-xl-6, .my-xl-6 {
		margin-bottom: 2rem !important;
	}
	.ml-xl-6, .mx-xl-6 {
		margin-left: 2rem !important;
	}
	.m-xl-7 {
		margin: 3rem !important;
	}
	.mt-xl-7, .my-xl-7 {
		margin-top: 3rem !important;
	}
	.mr-xl-7, .mx-xl-7 {
		margin-right: 3rem !important;
	}
	.mb-xl-7, .my-xl-7 {
		margin-bottom: 3rem !important;
	}
	.ml-xl-7, .mx-xl-7 {
		margin-left: 3rem !important;
	}
	.m-xl-8 {
		margin: 4rem !important;
	}
	.mt-xl-8, .my-xl-8 {
		margin-top: 4rem !important;
	}
	.mr-xl-8, .mx-xl-8 {
		margin-right: 4rem !important;
	}
	.mb-xl-8, .my-xl-8 {
		margin-bottom: 4rem !important;
	}
	.ml-xl-8, .mx-xl-8 {
		margin-left: 4rem !important;
	}
	.m-xl-9 {
		margin: 6rem !important;
	}
	.mt-xl-9, .my-xl-9 {
		margin-top: 6rem !important;
	}
	.mr-xl-9, .mx-xl-9 {
		margin-right: 6rem !important;
	}
	.mb-xl-9, .my-xl-9 {
		margin-bottom: 6rem !important;
	}
	.ml-xl-9, .mx-xl-9 {
		margin-left: 6rem !important;
	}
}

/*------ Components -------*/