
.border {
	border: 1px solid $border-color !important;
}
.border-top {
	border-top: 1px solid $border-color !important;
}
.border-right {
	border-right: 1px solid $border-color !important;
}
.border-bottom {
	border-bottom: 1px solid $border-color !important;
}
.border-left {
	border-left: 1px solid $border-color !important;
}
.border-0 {
	border: 0 !important;
}
.border-top-0 {
	border-top: 0 !important;
}
.border-right-0 {
	border-right: 0 !important;
}
.border-bottom-0 {
	border-bottom: 0 !important;
}
.border-left-0 {
	border-left: 0 !important;
}
.border-success {
	border-color: #3eb750 !important;
}
.border-info {
	border-color: #23bcb7 !important;
}
.border-warning {
	border-color: #ffb609 !important;
}
.border-danger {
	border-color: #fe6567 !important;
}
.border-light {
	border-color: $border-color !important;
}
.border-width-1 {
	border-width: 1px !important;
}
.border-width-2 {
	border-width: 2px !important;
}
.border-width-3 {
	border-width: 3px !important;
}
.border-width-4 {
	border-width: 4px !important;
}
.border-width-5 {
	border-width: 5px !important;
}
.border-white-transparent {
	border-color: rgba(255, 255, 255, 0.15) !important;
}
.border-white-transparent-5 {
	border-color: rgba(255, 255, 255, 0.5) !important;
}
.border-dark {
	border-color: #343a40 !important;
}
.border-dark-transparent {
	border-color: $border-color !important;
}
.border-white {
	border-color: #fff !important;
}