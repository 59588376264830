/*-------- Custom Card CSS -------*/
.item-card {
	.item-card-desc:before {
		content: '';
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		right: 0;
		top: 0;
		z-index: 1;
		border-radius: 5px;
	}
	overflow: hidden;
	border-radius: 5px;
	box-shadow: 5px 4px 12px 4px rgba(79, 37, 225, 0.03);
}
.item-card-desc {
	position: relative;
	a {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 10;
	}
}
.item-card {
	.item-card-desc .item-card-text {
		position: absolute;
		left: 0px;
		color: #fff;
		bottom: 0px;
		z-index: 2;
		right: 0px;
		padding: 20px;
		transition: all .5s;
	}
	&:hover.item-card-desc .item-card-text {
		transition: all .5s;
	}
}
.business-categories-desc .item-card:hover .item-card-desc .item-card-text {
	bottom: 0px;
	transition: all .5s;
}
.item-card {
	.item-card-desc .item-card-text p {
		display: none;
		transition: all .5s ease 0s;
	}
	&:hover .item-card-desc .item-card-text p {
		display: block;
		transition: all .5s ease 0s;
	}
}
.business-item .item-card .item-card-desc .item-card-text {
	top: 40%;
}
.item-card-text {
	span {
		font-size: 12px;
		display: block;
		margin-top: .7rem;
		font-weight: 500;
	}
	h4 {
		font-size: 16px;
		font-weight: 600;
	}
}
.item-card {
	.item-card-btn {
		padding: 0;
		margin: 0;
		opacity: 0;
		left: 20px;
		text-align: center;
		position: absolute;
		bottom: -30px;
		z-index: 1;
		transition: all .5s ease 0s;
	}
	&:hover {
		.item-card-btn {
			opacity: 1;
			bottom: 20px;
		}
		.data1 .item-card-img img {
			transform: scale(1);
		}
	}
	.item-card-btn a {
		display: block;
		position: relative;
		transition: all .3s ease-in-out;
		&:after, &:before {
			transform: translateX(-50%);
			position: absolute;
			left: 50%;
			top: -30px;
		}
		&:after {
			content: '';
			height: 15px;
			width: 15px;
			border-radius: 0;
			transform: translateX(-50%) rotate(45deg);
			top: -20px;
			z-index: -1;
		}
	}
}
.item-card2-icons {
	position: absolute;
	top: 15px;
	right: 15px;
	z-index: 9;
}
.item-card2-img1 {
	position: absolute;
	bottom: 15px;
	left: 15px;
	z-index: 9;
	cursor: pointer;
}
.item-card2-icons a {
	width: 2rem;
	display: inline-block;
	height: 2rem;
	text-align: center;
	border-radius: 100px;
	line-height: 2.1rem;
	border-radius: 50px;
	color: #fff;
	background: rgba(49, 45, 101, 0.65);
	&:hover {
		color: #fff;
	}
}
.item-list .list-group-item {
	border: 0;
	margin-bottom: 0;
	border-bottom: 1px solid $border-color;
	i {
		margin-right: 5px;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		font-size: 12px;
		text-align: center;
		line-height: 30px;
		color: #fff !important;
	}
}
.item-user .item-user-icons a {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	text-align: center;
	border-radius: 100px;
	line-height: 2rem;
	margin-top: .3rem;
}
.item-card3-img img {
	width: 100%;
	height: 100%;
	max-height: 200px;
	margin: 0 auto;
	position: relative;
	margin-bottom: -35px;
}
.item-card3-desc {
	img {
		border: 3px solid #fff;
	}
	h4 {
		position: relative;
		top: 5px;
	}
}
.item-card4-img a {
	position: absolute;
	top: 35px;
	left: 30px;
}
#count-down1 .clock-presenter {
	padding: 0;
	text-align: center;
	.digit {
		font-size: 20px;
		line-height: 40px;
		height: 40px;
		display: inline-block;
		overflow: hidden;
		text-align: center;
		position: relative;
		cursor: default;
		background: #fff;
		color: #000;
		border-radius: 5px;
	}
	.note {
		position: relative;
		margin-bottom: 0;
		cursor: default;
		font-size: 14px;
		opacity: .7;
	}
}
.item-card5-img a {
	position: absolute;
	right: 12px;
	top: 15px;
}
.item-card5-icon a {
	width: 2rem;
	height: 2rem;
	display: inline-block;
	text-align: center;
	border-radius: 100px;
	line-height: 2rem;
	border-radius: 50px;
	background: $border-color;
	color: #3f3e50;
}
.item-card6-img img {
	width: 100%;
	height: 100%;
	max-height: 200px;
	margin: 0 auto;
}
.item-card6-footer .item-card6-body {
	padding: 10px;
}
.item-search-tabs {
	margin-top: 2rem;
	.item-search-menu ul {
		padding: 0;
		margin-bottom: -2px;
	}
}
.item-search-menu ul li .active, .item-search-tabs-dark .item-search-menu ul li .active {
	background: rgba(49, 45, 101, 0.3);
	color: #fff;
	border-radius: 5px 5px 0 0;
}
.item-search-menu ul li a {
	padding: 10px 16px;
	color: #fff;
	text-align: center;
	letter-spacing: .5px;
	display: block;
	background: 0 0;
	margin: 1px 0px;
	border-radius: 5px 5px 0 0;
	font-weight: 400;
	margin-left: 10px;
}
.item-search-tabs .tab-content {
	background: rgba(49, 45, 101, 0.3);
	padding: 15px;
	margin: 1px 5px 0 0;
	border-radius: 5px;
}
.sub-input .form-control {
	box-shadow: 5px 4px 12px 4px rgba(79, 37, 225, 0.03);
}
.item-search-tabs {
	&.travel-content {
		.select2-container {
			width: 100% !important;
		}
		.form-control {
			border: 1px solid #fff;
		}
	}
	.form-control {
		border: 1px solid #fff;
	}
	.select2-container--default .select2-selection--single {
		border: 1px solid #ffffff !important;
	}
	a.btn {
		font-size: .8525rem;
		position: relative;
		line-height: 2;
	}
}
.item-all-cat {
	.item-all-card {
		position: relative;
		padding: 1.5rem 1.5rem;
		border-radius: 4px;
		margin-bottom: 1.5rem;
		color: #fff;
		border: 1px solid $border-color;
	}
	.category-type .item-all-card .iteam-all-icon1 {
		width: 7rem;
		height: 7rem;
		border-radius: 50%;
		padding: 2.3rem 0;
	}
	.row .item-all-card {
		margin-bottom: 1.5rem;
		box-shadow: 0 10px 40px 0 rgba(62, 57, 107, 0.1), 0 2px 9px 0 rgba(62, 57, 107, 0.1);
	}
	.item-all-card a {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
}
.iteam-all-icon i {
	font-size: 1.5rem;
}
.item-all-card img.imag-service {
	width: 40%;
}
.item-card7-overlaytext {}
.item-card7-imgs .bdir {
	position: absolute;
	top: 12px;
	left: 12px;
	padding: 3px 7px;
	z-index: 2;
}
.item-card7-overlaytext {
	a span {
		padding: 4px 10px;
		display: inline-block;
	}
	span {
		position: absolute;
		top: 15px;
		left: 15px;
		z-index: 2;
		background: rgba(49, 45, 101, 0.8);
		border-radius: 5px;
		padding: 4px 15px;
		line-height: 1.2;
	}
	h4 {
		position: absolute;
		bottom: 12px;
		right: 0;
		color: #fff;
		z-index: 2;
		padding: 7px 25px;
		border-radius: 5px 0 0 5px;
		font-weight: 600;
		font-size: 18px;
	}
}
.item-card7-overlaytext2 h4 {
	position: absolute;
	bottom: 12px;
	right: 0;
	color: #fff;
	z-index: 2;
	padding: 8px 25px;
	border-radius: 5px 0 0 5px;
	font-weight: 500;
	font-size: 18px;
	display: flex;
}
.item-card7-overlaytext {
	small {
		position: absolute;
		bottom: 12px;
		right: 12px;
		color: #fff;
		z-index: 2;
		padding: 7px 15px;
		border-radius: 5px;
		font-weight: 500;
		background: linear-gradient(rgba(49, 45, 101, 0.75) 10%, rgba(49, 45, 101, 0.75));
	}
	.overlay-btn {
		position: absolute;
		bottom: 12px;
		left: 0;
		z-index: 0;
		right: 0;
		margin: 0 auto;
		text-align: center;
		top: 20%;
		opacity: 0;
		transition: all 0.4s;
		z-index: 2;
		.btn {
			padding: 7px 25px;
		}
	}
}
.item-card2-card:hover .item-card7-overlaytext .overlay-btn {
	opacity: 1;
	top: 45%;
	transition: all 0.4s;
}
.item-card7-imgs {
	position: relative;
	overflow: hidden;
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
	&:before {
		content: '';
		display: block;
		position: absolute;
		background: rgba(49, 45, 101, 0);
		width: 100%;
		height: 100%;
		right: 0;
		top: 0;
		z-index: 1;
	}
}
.item-card8-img {
	height: 100%;
}
.item-card8-imgs {
	position: relative;
	overflow: hidden;
	border-top-right-radius: 2px;
	border-top-left-radius: 2px;
	img {
		-webkit-transform: scale(1.1);
		transform: scale(1.1);
		-webkit-transition: .3s ease-in-out;
		transition: .3s ease-in-out;
	}
	&:before {
		content: '';
		display: block;
		position: absolute;
		background: rgba(49, 45, 101, 0);
		width: 100%;
		height: 100%;
		right: 0;
		top: 0;
		z-index: 1;
	}
}
.item-card8-overlaytext {
	position: relative;
	h6 {
		position: absolute;
		bottom: 21px;
		left: 21px;
		z-index: 2;
		padding: 5px 12px;
		color: #fff;
		border-radius: 5px;
		font-weight: 400;
	}
}
.item-card2-img:before {
	content: '';
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	right: 0;
	top: 0;
	z-index: 1;
	overflow: hidden;
}
.item-card7-imgs a {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	i {
		margin: 0 auto;
		top: 35%;
		font-size: 40px;
		text-align: center;
		left: 0;
		right: 0;
		display: block;
		color: #fff;
		position: absolute;
	}
}
.item-card9-img .item-card9-imgs a {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 9;
	i {
		margin: 0 auto;
		top: 35%;
		font-size: 60px;
		text-align: center;
		left: 0;
		right: 0;
		display: block;
		color: #fff;
		position: absolute;
	}
}
.item-card2-img a.absolute-link {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	i {
		margin: 0 auto;
		top: 35%;
		font-size: 40px;
		text-align: center;
		left: 0;
		right: 0;
		display: block;
		color: #fff;
		position: absolute;
	}
}
.item-card7-desc ul li {
	margin-right: 1rem;
	font-size: 13px;
	&:last-child {
		margin-right: 0;
	}
}
.item1-card-img img {
	border: 1px solid $border-color;
	margin: 0 auto;
	padding: 5px;
}
.item1-card .item1-card-info .item1-card-icons li {
	display: inline-block;
	margin-right: 15px;
	a {
		color: #cecde0;
	}
}
.item1-card-tab {
	text-align: center;
	.tab-content .tab-pane {
		border: 1px solid $border-color;
	}
}
.item1-tab-heading {
	padding: 15px;
	border: 1px solid $border-color;
	text-align: center;
	border-radius: 50px;
	margin: 0 auto;
	display: inline-table;
}
.item1-tabs-menu ul li {
	a {
		padding: 10px 20px 11px 20px;
	}
	.active {
		color: #fff;
		border-radius: 25px;
	}
}
.item1-card-tab .tab-content {
	padding: 15px;
	text-align: initial;
}
.card-video {
	background: url(../images/products/b5.png);
	background-size: cover;
	&:before {
		content: '';
		display: block;
		position: absolute;
		background: rgba(49, 45, 101, 0.6);
		width: 100%;
		height: 100%;
		right: 0;
		top: 0;
	}
}
.item-video {
	a {
		display: inline-block;
		width: 2rem;
		height: 2rem;
		text-align: center;
		border-radius: 100px;
		line-height: 2rem;
		background: #fff;
		color: #3f3e50;
		font-size: 25px;
		align-items: center;
		margin: 0 auto;
		justify-content: center;
	}
	i {
		margin-left: 5px;
	}
}
.item2-card .item2-card-img {
	border: 1px solid $border-color;
	padding: 5px;
	img {
		width: 200px;
		height: 100px;
		text-align: center;
		margin: 0 auto;
	}
}
.item2-card-info {
	padding-top: 15px;
}
.item2-btn {
	padding-top: 40px;
}
.media-list .media-icons {
	font-size: 18px;
	color: #000;
}
.items-gallery .items-blog-tab-heading {
	text-align: center;
	border-radius: 50px;
	margin: 0 auto;
	display: inline-table;
	padding: 15px;
	margin-bottom: 30px;
}
.items-blog-tab-heading .items-blog-tab-menu li a {
	padding: 7px 20px 7px 20px;
	color: #3f3e50;
	background: #fff;
	margin: 0 10px;
	border-radius: 5px;
	display: block;
	border: 1px solid $border-color;
	box-shadow: 0px 0px 12px 0px rgba(79, 37, 225, 0.03);
}
.items-gallery .tab-content {
	padding: 15px;
	text-align: initial;
}
.item2-gl .item2-gl-nav select {
	width: 20%;
}
.item2-gl-nav {
	padding: 15px;
	border: 1px solid $border-color;
	text-align: right;
	border-radius: 5px;
	background: $border-color;
	.item2-gl-menu {
		margin-right: 10px;
		li {
			&:first-child a {
				border-radius: 5px 0 0 5px;
				border-right: 0;
			}
			&:last-child a {
				border-radius: 0 5px 5px 0;
			}
			a {
				padding: 7px 10px 5px 10px;
				color: #797896;
				line-height: 34px;
				font-size: 20px;
				border: 1px solid $border-color;
			}
		}
	}
}
.item2-gl .tab-content {
	padding-top: 1.5rem;
	text-align: initial;
}
.item-card9-img {
	position: relative;
	height: 100%;
}
.item2-gl {
	.item-card9 p {
		line-height: 1.8;
	}
	.item-card9-icons {
		position: absolute;
		top: 15px;
		left: 15px;
		z-index: 9;
	}
}
.item-card9-icons {
	position: absolute;
	top: 20px;
	right: 15px;
	z-index: 9;
	a {
		width: 2rem;
		display: inline-block;
		height: 2rem;
		text-align: center;
		border-radius: 100px;
		line-height: 2.1rem;
		border-radius: 50px;
		color: #fff;
		background: rgba(49, 45, 101, 0.3);
	}
}
.item-card9-cost h3 {
	margin-top: 5px;
}
.item-card9-imgs {
	position: relative;
	overflow: hidden;
	a {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
}
.item-card9-desc ul {
	display: inline-flex;
	margin-bottom: 0;
	li {
		width: 50%;
		float: left;
	}
}
.item3-medias .item3-lists li {
	a {
		padding: 5px 4px 5px 0;
		font-size: 12px;
	}
	i {
		margin-right: 5px;
	}
	margin-right: 5px;
}
.item-card2-img {
	position: relative;
	overflow: hidden;
}
.item-card2-desc ul li {
	margin-right: 1.5rem;
	font-size: 13px;
	&:last-child {
		margin-right: 0;
	}
}
.map-header1 {
	margin-bottom: 1.5rem;
	height: 23.2rem;
	position: relative;
	margin-bottom: -1.5rem;
	border: 1px solid $border-color;
}
.item7-card-desc a:first-child {
	margin-right: 1rem;
}
.item7-card-img a {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}
.item-cards7-ic {
	display: inline-block;
	margin-top: 10px;
	&.realestate-list li {
		a {
			i {
				width: 1rem;
			}
			color: #3d4e67;
		}
		width: 50%;
	}
	li {
		float: left;
		width: 50%;
		margin-right: 0 !important;
		margin-bottom: .5rem;
		i {
			margin: 0 auto;
			text-align: center;
			font-size: 12px;
			width: 22px;
			height: 22px;
			background: $border-color;
			line-height: 22px;
			border-radius: 50%;
			display: inline-block;
		}
	}
}
.item7-card-text span {
	position: absolute;
	bottom: 10px;
	left: 10px;
	z-index: 9;
	line-height: 1;
	font-size: 12px;
}
.item-card-img {
	overflow: hidden;
}
.item-card .item-card-desc {
	img {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transition: .3s ease-in-out;
		transition: .3s ease-in-out;
		width: 100%;
		height: 100%;
	}
	&:hover img {
		-webkit-transform: scale(1.2);
		transform: scale(1.2);
	}
}
.item-card7-imgs {
	img {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transition: .3s ease-in-out;
		transition: .3s ease-in-out;
	}
	&:hover img {
		-webkit-transform: scale(1.1);
		transform: scale(1.1);
	}
}
.item-card9-imgs img {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}
.item-card2 p.leading-tight, .item-card9 p.leading-tight {
	display: block;
	display: -webkit-box;
	max-width: 100%;
	margin: 0 auto;
	font-size: 14px;
	line-height: 1;
	-webkit-line-clamp: 2;
	-moz-line-clamp: 2;
	-webkit-box-orient: vertical;
	-moz-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}
#tab-11 .item-card2-img:hover a, #tab-12 .item-card2-img:hover a {
	background: 0 0;
}
.item2-gl .card-footer {
	padding: 0.75rem 1.5rem;
}
.item-card9-imgs:hover img {
	-webkit-transform: scale(1);
	transform: scale(1);
}
.item-card2-img {
	img {
		-webkit-transform: scale(1.1);
		transform: scale(1.1);
		-webkit-transition: .3s ease-in-out;
		transition: .3s ease-in-out;
	}
	&:hover img {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}
.widget-spec li {
	font-size: .9rem;
	display: flex;
	padding: 9px 25px;
	border-bottom: 1px solid rgb(240, 243, 249);
	&:last-child {
		border-bottom: 0 !important;
	}
}
.widget-spec1 li {
	margin-bottom: .5rem;
	font-size: .9rem;
}
.support-list li {
	a {
		margin-bottom: .5rem;
		display: flex;
	}
	i {
		margin-right: .7rem;
		margin-top: 4px;
	}
}
.widget-spec li {
	&.icon:before {
		content: '\f00c';
		position: absolute;
		width: 100%;
		height: 100%;
		display: block;
		font-family: fontawesome;
	}
	i {
		margin-right: .5rem;
		margin-top: 3px;
	}
}
.profile-pic-img {
	position: relative;
	width: 5rem;
	height: 5rem;
	line-height: 5rem;
	font-size: 2rem;
	text-align: center;
	margin: 0 auto;
	span {
		position: absolute;
		width: 1rem;
		height: 1rem;
		border-radius: 50px;
		right: -1px;
		top: .5rem;
		border: 2px solid #fff;
	}
}
.sub-newsletter {
	display: inline-block;
}
.item1-links a {
	padding: .75rem 1.25rem;
	font-weight: 400;
	font-size: .875rem;
	color: #3f3e50;
	&.active {
		font-weight: 400;
		font-size: .875rem;
	}
}
.user-tabel span {
	margin: 0 auto;
}
.settings-tab .tabs-menu {
	margin-bottom: 25px;
	border: 1px solid $border-color;
	border-radius: 4px;
	li {
		width: 33.3%;
		display: block;
		a {
			padding: .8rem 1rem;
			background: #fff;
			display: block;
			text-align: center;
			border-right: 1px solid $border-color;
		}
		&:last-child a {
			border-right: 0;
		}
		a.active {
			border-radius: 0px;
			text-align: center;
		}
	}
}
.ads-tabs .tabs-menus {
	margin-bottom: 2rem;
	ul li {
		a {
			border: 1px solid $border-color;
			color: #3f3e50;
			padding: 10px 20px 11px 20px;
			border-radius: 4px;
			background: $border-color;
		}
		margin-right: 1rem;
		&:last-child {
			margin-right: 0;
		}
	}
}
.item-card7-img {
	position: relative;
	height: 100%;
	&:before {
		content: '';
		display: block;
		position: absolute;
		background: rgba(49, 45, 101, 0);
		width: 100%;
		height: 100%;
		right: 0;
		top: 0;
	}
	a {
		position: absolute;
	}
	h4 {
		position: absolute;
		bottom: 12px;
		right: 15px;
		font-size: 25px;
		color: #fff;
	}
}
.item-card7-icons {
	position: absolute;
	top: 15px;
	right: 15px;
	z-index: 98;
	a {
		width: 2rem;
		display: inline-block;
		height: 2rem;
		text-align: center;
		border-radius: 100px;
		line-height: 2.1rem;
		border-radius: 50px;
		color: #fff;
		background: rgba(0, 0, 0, 0.5);
	}
}
.widget-spec.tour-scroll li a{
	color:$text-color;
}
/*-------- Custom Card CSS -------*/
