/* Z index */

.zindex-0 {
	z-index: 0;
}
.zindex-1 {
	z-index: 1;
}
.zindex-2 {
	z-index: 2;
}
.zindex-3 {
	z-index: 3;
}
.zindex-4 {
	z-index: 4;
}
.zindex-5 {
	z-index: 5;
}
.zindex-6 {
	z-index: 6;
}
.zindex-7 {
	z-index: 7;
}
.zindex-8 {
	z-index: 8;
}
.zindex-9 {
	z-index: 9;
}
.zindex-10 {
	z-index: 10;
}
.z-index-19{
	z-index:19;
}