/*-------- Custom-01 CSS -------*/
#sidebar {
	ul li a {
		color: #bec8d6;
		font-weight: 400;
		border-top: 1px solid rgba(255, 255, 255, 0.08);
		.fa {
			width: 26px;
			opacity: .99;
			display: inline-block;
			font-family: FontAwesome;
			font-style: normal;
			font-weight: 400;
			font-size: 13px;
			-webkit-font-smoothing: antialiased;
			text-shadow: none;
		}
	}
	li a[aria-expanded=true] {
		background: #0d102c;
		color: #fff;
		box-shadow: 0 0 10px #9ecaed;
		border-bottom: 1px solid rgba(255, 255, 255, 0.08);
		&.active:before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 5px;
			height: 100%;
			display: block;
			box-shadow: 0 0 14px 0 #0f75ff;
		}
	}
	.collapse li a:hover {
		background: rgba(49, 45, 101, 0.01);
		border-radius: 7px;
	}
}
.tab_wrapper {
	>ul {
		li {
			border: 1px solid $border-color;
			border-top: 3px solid $border-color;
		}
		border-bottom: 1px solid $border-color;
	}
	&.right_side {
		.content_wrapper {
			border: 1px solid $border-color;
		}
		>ul {
			li {
				&.active {
					border-color: $border-color;
				}
				&:after {
					background: $border-color;
				}
			}
			border-bottom: 1px solid $border-color;
		}
	}
}
.inbox-message {
	ul {
		padding: 0;
		margin: 0;
		li {
			list-style: none;
			position: relative;
			padding: 15px 20px;
			border-bottom: 1px solid $border-color;
			&:focus, &:hover {
				background: $border-color;
			}
		}
	}
	.message-avatar {
		position: absolute;
		left: 30px;
		top: 50%;
		transform: translateY(-50%);
	}
}
.message-avatar img {
	display: inline-block;
	width: 54px;
	height: 54px;
	border-radius: 50%;
}
.inbox-message .message-body {
	margin-left: 85px;
	font-size: 15px;
	color: #cecde0;
}
.message-body-heading h5 {
	font-weight: 600;
	display: inline-block;
	color: #cecde0;
	margin: 0 0 7px 0;
	padding: 0;
}
.message-body h5 span {
	border-radius: 50px;
	line-height: 14px;
	font-size: 12px;
	color: #fff;
	font-style: normal;
	padding: 4px 10px;
	margin-left: 5px;
	margin-top: -5px;
	&.unread {
		background: #07b107;
	}
	&.important {
		background: #dd2027;
	}
	&.pending {
		background: #2196f3;
	}
}
.message-body-heading span {
	float: right;
	font-size: 14px;
}
.messages-inbox .message-body p {
	margin: 0;
	padding: 0;
	line-height: 27px;
	font-size: 15px;
}
#index-video {
	.index-video-container {
		position: relative;
		overflow: hidden;
		&:after {
			bottom: 0;
			content: "";
			left: 0;
			opacity: .7;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 1;
		}
		.videocaption {
			left: 1%;
			position: absolute;
			right: 1%;
			text-align: center;
			top: 30%;
			z-index: 2;
			h2 {
				color: #fff;
				font-size: 4.5em;
				font-weight: 900;
				-webkit-box-sizing: content-box;
				-moz-box-sizing: content-box;
				box-sizing: content-box;
				border: none;
				color: rgba(255, 255, 255, 1);
				text-align: center;
				-o-text-overflow: clip;
				text-overflow: clip;
				text-shadow: 0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(49, 45, 101, 0.0980392), 0 0 5px rgba(49, 45, 101, 0.0980392), 0 1px 3px rgba(49, 45, 101, 0.298039), 0 3px 5px rgba(49, 45, 101, 0.2), 0 5px 10px rgba(49, 45, 101, 0.247059), 0 10px 10px rgba(49, 45, 101, 0.2), 0 20px 20px rgba(49, 45, 101, 0.14902);
				-webkit-transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
				-moz-transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
				-o-transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
				transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
			}
			h4 {
				color: #fff;
				font-size: 2em;
				margin-top: 25px;
			}
			.btn-slide {
				background: #f62459;
				color: #fff;
				font-size: 16px;
				font-weight: 400;
				margin: 25px auto 10px auto;
				padding: 20px 50px;
				border-radius: 0;
				text-transform: uppercase;
				&:hover {
					background: #fff;
					color: $text-color;
				}
			}
		}
	}
	video {
		width: 100%;
		background-size: cover;
		position: absolute;
		top: 0;
		left: 0;
	}
}
.video-list-thumbs {
	>li {
		margin-bottom: 12px;
		>a {
			display: block;
			position: relative;
			color: #6b6f80;
			border-radius: 5px;
			transition: all .5s ease-in-out;
			border-radius: 4px;
		}
	}
	h2 {
		bottom: 0;
		font-size: 14px;
		height: 33px;
		margin: 8px 0 0;
	}
	span {
		font-size: 50px;
		opacity: .8;
		position: absolute;
		right: 0;
		left: 0;
		top: 42%;
		margin: 0 auto;
		text-align: center;
		transition: all .5s ease-in-out;
		z-index: 5;
	}
	>li>a:hover .fa {
		color: #fff;
		opacity: 1;
		text-shadow: 0 1px 3px rgba(49, 45, 101, 0.8);
	}
	.duration {
		background-color: $color-dark-purple;
		border-radius: 0;
		color: #fff;
		font-size: 13px;
		font-weight: 700;
		left: 12px;
		line-height: 9px;
		padding: 5px 8px;
		position: absolute;
		bottom: 12px;
		transition: all .5s ease;
	}
	>li>a:hover .duration {
		background-color: #000;
	}
}
.all-categories .row {
	.card-body {
		border-bottom: 1px solid $border-color;
	}
	&:last-child .card-body {
		border-bottom: 0 !important;
	}
}
.clear, .current, .ok {
	background: #e9ecfb;
	border-radius: 20px;
}
.today {
	background: #e9ecfb;
}
.graph canvas {
	width: 100% !important;
}
.trend {
	i {
		font-size: 10px !important;
		vertical-align: middle;
	}
	.media-body {
		margin-left: 10px;
	}
}
.widgetdate {
	float: right;
	border-radius: 4px;
	background: #fff;
	padding: 0 15px;
	p {
		margin-bottom: 0;
		margin-top: 5px;
	}
	h6 {
		font-size: 22px;
		font-weight: 600;
		margin-top: 0;
		margin-bottom: 5px;
	}
}
.footerimg img {
	width: 35px;
	height: 35px;
}
.card-img-overlay1 {
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 1.25rem;
	.card-trans {
		position: absolute;
		bottom: 20px;
		left: 15px;
		right: 15px;
		background: #0d102c;
		padding: 20px;
		border-radius: 7px;
	}
	.widgetbox {
		top: 0;
		left: 0;
		border-radius: 7px;
		background: #fff;
		padding: 0 15px 0 15px;
	}
}
.user-wideget-text {
	position: relative;
	margin-bottom: -38px;
}
.widget-image img {
	width: 50px;
	height: 50px;
	border: 5px solid #fff;
}
.wideget-img {
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	position: absolute;
	width: 100%;
	height: 100%;
	padding: 1.25rem;
}
.datebox {
	top: 0;
	left: 0;
	border-radius: 7px;
	background: #fff;
	padding: 0 15px 0 15px;
}
.iconbage .badge {
	position: absolute;
	top: 14px;
	right: 13px;
	padding: .2rem .25rem;
	min-width: 1rem;
	font-size: 13px;
}
.single-product .product-desc .product-icons .socialicons a {
	width: 2em;
	height: 2em;
	line-height: 2em;
	border-radius: 50%;
	font-size: 1em;
	display: inline-block;
	vertical-align: middle;
	border: 1px solid #eef2f9;
	color: #3f3e50;
	margin: 0 auto;
	text-align: center;
}
.card-blog-overlay6 {
	background: url(../images/banners/subscribe.jpg);
	background-size: cover;
	&:before {
		content: '';
		display: block;
		position: absolute;
		background: rgba(107, 34, 251, 0.6);
		width: 100%;
		height: 100%;
		right: 0;
		top: 0;
	}
}
.card-aside-column1 {
	min-width: 8rem;
	width: 40%;
	-ms-flex: 0 0 40%;
	flex: 0 0 40%;
	background: no-repeat center/cover;
}
.blogimg {
	width: 100px;
}
.subnews img {
	width: 25%;
	height: 25%;
}
.task-img img, .status-img img {
	width: 70px;
	height: 70px;
	margin: 0 auto;
}
.product-hover {
	position: relative;
	.product-info-buttons {
		position: absolute;
		display: none;
		text-align: center;
		top: 45%;
		margin: 0 auto;
		left: 0;
		width: 100%;
		height: 100%;
		right: 0;
	}
	&:hover {
		&.product-hover:before {
			content: "";
			background: rgba(49, 45, 101, 0.5);
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 100;
		}
		.product-info-buttons {
			display: block;
			z-index: 1000;
		}
	}
}
.spacing {
	padding: 1.5rem;
}
.search-background {
	background: $form-background;
	border-radius: 5px;
}
.search1 {
	background: #fff;
	border-radius: 2px;
	overflow: hidden;
}
.search2 {
	background: #fff;
	padding: 30px;
	border-radius: 2px;
	overflow: hidden;
}
.search3 {
	background: #fff;
	border-radius: 2px;
	a.btn {
		font-size: .8625rem;
	}
}
.card-blog-img {
	background: url(../images/banners/tour.png);
	background-size: cover;
	&:before {
		content: '';
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		right: 0;
		top: 0;
	}
}
.product-single .product-thumbimg img {
	width: 130px;
	height: 130px;
}
.page-header .page-select {
	width: 20%;
}
.footer-main .social li {
	float: left;
	margin-right: 15px;
	display: inline-block;
	list-style: none;
	font-size: 20px;
	a {
		color: #cecde0;
		line-height: 0;
	}
}
.social li {
	float: left;
	margin-right: 15px;
	display: inline-block;
	list-style: none;
	font-size: 15px;
	float: right;
}
.social-icons li {
	a {
		color: #fff;
	}
	margin-right: 15px;
	display: inline-block;
	list-style: none;
	font-size: 14px;
	width: 35px;
	height: 35px;
	margin: 1px;
	border-radius: 50%;
	border: 1px solid rgba(255, 255, 255, 0.2);
	line-height: 35px;
	margin: 1px auto;
	text-align: center;
	cursor: pointer;
}
.login-social-icons li {
	margin-right: 15px;
	display: inline-block;
	list-style: none;
	font-size: 18px;
	width: 45px;
	height: 50px;
	height: 45px;
	border-radius: 50%;
	border: 0px solid rgba(255, 255, 255, 0.2);
	line-height: 47px;
	margin: 0px 3px;
	text-align: center;
	a {
		color: #fff;
	}
}
footer .social-icons li a {
	color: rgba(255, 255, 255, 0.6);
}
.footer-btn-outline {
	border: 1px solid rgba(255, 255, 255, 0.2);
	color: rgba(255, 255, 255, 0.6);
	background: transparent;
}
.bg-background .social-icons li {
	border: 0;
	background: rgba(49, 45, 101, 0.35);
}
.social li a {
	color: rgba(255, 255, 255, 0.6);
	line-height: 0;
}
.footer-main {
	.payments li {
		float: left;
		margin-right: 15px;
		display: inline-block;
		list-style: none;
		font-size: 20px;
		a {
			color: rgba(255, 255, 255, 0.6);
			line-height: 0;
		}
	}
	h6 {
		font-size: 16px;
		margin-bottom: 1.2rem;
	}
}
footer .border-bottom {
	border-bottom: 1px solid rgba(167, 180, 201, 0.2) !important;
}
.footer-main {
	p {
		color: rgba(255, 255, 255, 0.7);
	}
	a {
		line-height: 2;
		transition: opacity .2s;
		color: rgba(255, 255, 255, 0.6);
	}
	&.footer-main1 {
		a {
			color: #797896;
		}
		.form-control {
			border: 1px solid $border-color !important;
		}
	}
}
.footer-links {
	a {
		color: rgba(255, 255, 255, 0.6);
		position: relative;
		&:first-child:before {
			background: transparent !important;
		}
		&:before {
			content: '';
			position: absolute;
			width: 1px;
			height: 15px;
			background: rgba(255, 255, 255, 0.15);
			left: -4px;
			z-index: 17;
			display: block;
			top: 11px;
		}
	}
	&.footer-links2 a {
		&:before {
			height: 100%;
			top: 0;
		}
		&:first-child:before {
			background: rgba(255, 255, 255, 0.15);
		}
		&:last-child:after {
			content: '';
			position: absolute;
			width: 1px;
			height: 100%;
			background: rgba(255, 255, 255, 0.15);
			right: -4px;
			z-index: 17;
			display: block;
			top: 0;
		}
	}
}
.footer-light .footer-links a:before {
	background: rgba(25, 24, 31, 0.1);
}
.footer-links a {
	&:hover, &:focus {
		text-decoration: none;
	}
}
.list-catergory ul li {
	line-height: 2;
}
.product-tags {
	a {
		float: left;
		margin-right: 5px;
		margin-bottom: 5px;
		padding: 5px 10px;
		border-radius: 2px;
		color: #3f3e50;
		font-size: 12px;
		border: 1px solid $border-color;
	}
	li a:hover {
		border-radius: 2px;
		color: #fff !important;
	}
}
.media-posts {
	width: 100%;
	height: 233px;
	overflow: hidden;
	&:hover {
		overflow: scroll;
	}
}
.icon-bg i {
	font-size: 1.5rem;
}
.icon-service {
	display: block;
	width: 120px;
	height: 120px;
	text-align: center;
	border-radius: 50%;
	align-items: center;
	justify-content: center;
	line-height: 120px;
	margin: 0 auto;
	text-align: center;
}
.support-service {
	padding: 1.5rem 1.5rem;
	border: 1px solid $border-color;
	background: $border-color;
}
footer .support-service {
	border: 1px solid #19181f;
}
.support-service i, .support-service2 i {
	float: left;
	margin-right: 15px;
	font-size: 1rem;
	line-height: 45px;
	width: 45px;
	text-align: center;
	height: 45px;
	border-radius: 50%;
	background: rgba(255, 255, 255, 0.15);
}
.support-service {
	h6 {
		font-size: 16px;
		font-weight: 400;
		margin-bottom: 2px;
	}
	p {
		margin-bottom: 0;
		font-size: 14px;
		color: rgba(255, 255, 255, 0.7);
	}
}
.counter-icon {
	margin-bottom: 1.5rem;
	display: inline-flex;
	width: 4rem;
	height: 4rem;
	padding: 1.3rem 1.4rem;
	border-radius: 50%;
	text-align: center;
	background: rgba(255, 255, 255, 0.15);
	i {
		font-size: 1.2rem;
	}
}
.get-contact {
	a {
		color: #3f3e50;
		margin-right: 15px;
		font-size: 16px;
		display: inline-block;
	}
	i {
		font-size: 18px;
	}
}
.filter-product-social {
	.social-icon2 li {
		color: #3f3e50;
		margin-right: 35px;
		display: inline-block;
		list-style: none;
		font-size: 20px;
	}
	.social-icons li a {
		color: #3f3e50;
	}
}
.header-main .social-icons {
	float: right;
	li {
		color: #3f3e50;
		margin-right: 35px;
		display: inline-block;
		list-style: none;
		font-size: 20px;
		a {
			color: #3f3e50;
		}
	}
}
.header-search {
	padding: 1.5rem 1.5rem;
	background: #fff;
	.header-icons {
		.header-icons-link {
			display: flex;
			margin-bottom: 0;
			li a {
				background: transparent;
				width: 40px;
				height: 40px;
				border-radius: 50px;
				text-align: center;
				line-height: 2;
				margin-right: 5px;
				display: block;
				border: 1px solid $border-color;
				color: #3f3e50;
				font-size: 20px !important;
			}
			.header-icons-link1, .header-icons-link2 {
				width: 2.5rem;
				text-align: center;
				height: 2.5rem;
				font-size: 16px;
				position: relative;
				line-height: 2.7rem;
			}
		}
		.header-icons-link1 .main-badge1 {
			position: absolute;
			top: -3px;
			right: -8px;
			text-align: center;
			font-size: 10px;
			padding: 0.2rem 0.32rem !important;
		}
		.header-icons-link.icons li {
			background: #fff;
		}
	}
}
.top-bar {
	border-bottom: 1px solid $border-color;
	background: #fff;
	position: relative;
    z-index: 19;
}
.top-bar-left ul {
	margin-bottom: 0;
}
.top-bar {
	.top-bar-left {
		display: flex;
		.socials li {
			display: inline-block;
			float: left;
			font-size: 15px;
			padding: 9px 15px;
			margin: 0;
			border-left: 1px solid $border-color;
		}
		.contact li {
			margin: 10px 5px;
			display: inline-block;
			color: #3f3e50;
		}
	}
	.top-bar-right {
		display: flex;
		float: right;
	}
}
.header-search {
	.header-inputs .input-group-append.searchicon {
		top: 10px;
		right: 25px;
		position: relative;
	}
	.header-search-logo {
		margin-right: 1rem;
	}
	.header-nav .nav-cart .icon-cart {
		i {
			font-size: 18px;
			color: #cecde0;
		}
		a {
			color: #cecde0;
		}
	}
}
.header-nav {
	display: flex;
}
.header-links {
	padding: 3px;
	li {
		margin: 5px;
		a {
			padding: 9px 18px;
			border-radius: 5px;
		}
	}
}
.rated-products {
	.media {
		padding-bottom: 15px;
		border-bottom: 1px solid $border-color;
		&:last-child {
			padding-bottom: 0;
			border-bottom: 0;
		}
		img {
			width: 80px;
			height: 80px;
		}
	}
	img {
		border: 1px solid $border-color;
		padding: 5px;
		background: #f6f7fb;
	}
}
.product-info-img a {
	position: absolute;
	top: 35px;
	right: 30px;
}
.product-filter {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	margin-bottom: 15px;
	.product-filter-img {
		width: 60px;
		height: 60px;
	}
}
.product-filter-desc .product-filter-icons a {
	width: 2.5em;
	height: 2.5em;
	line-height: 2.6em;
	border-radius: 50%;
	font-size: 1em;
	display: inline-block;
	vertical-align: middle;
	&:hover {
		color: #fff;
	}
}
.facebook-bg {
	background: #3b5998;
	color: #fff;
}
.twitter-bg {
	background: #0c85d0;
	color: #fff;
}
.google-bg {
	background: #c63224;
	color: #fff;
}
.dribbble-bg {
	background: #e62a72;
	color: #fff;
}
.pinterest-bg {
	background: #8c0615;
	color: #fff;
}
.wideget-user-desc {
	.wideget-user-img img {
		border-radius: 100%;
	}
	.user-wrap {
		margin-top: 1.5rem;
	}
}
.wideget-user .wideget-user-icons a {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	text-align: center;
	border-radius: 100px;
	line-height: 2rem;
	margin-top: .3rem;
	background: $border-color;
	&:hover {
		color: #fff;
	}
}
.wideget-user-info {
	.wideget-user-warap {
		display: flex;
		h4 {
			font-size: 1.5rem;
			font-weight: 500;
			margin-bottom: .4rem;
		}
		.wideget-user-warap-r {
			margin-left: 10rem;
		}
	}
	.wideget-user-rating a {
		font-size: 18px;
	}
}
.wideget-user-tab .tab-menu-heading {
	padding: 0;
	border: 0;
	.nav li a {
		color: #3f3e50;
		font-size: 15px;
		font-weight: 500;
		margin-bottom: -1px;
		margin-left: 0;
		border: 0;
	}
}
.usertab-list {
	display: inline-block;
	li {
		width: 50%;
		margin-bottom: .85rem;
		float: left;
		border-bottom: 1px dashed $border-color;
		padding-bottom: .85rem;
	}
}
.wideget-user-tab .tabs-menu1 ul li {
	a {
		padding: 8px 17px 8px 17px;
		display: block;
		background: $border-color;
		border: 0;
		margin-right: 5px;
		border-radius: 4px 4px 0 0;
	}
	display: block;
}
.item-box {
	.item-box-wrap {
		margin-top: 15px;
	}
	.stamp i {
		font-size: 18px;
		font-size: 25px;
		line-height: 2;
	}
	.item-box-wrap h5 {
		font-size: 20px;
		font-weight: 600;
	}
}
.widgets-cards .widgets-cards-data {
	margin-top: 8px;
	.wrp p:first-child {
		font-size: 20px;
		font-weight: 600;
	}
}
.card-item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
}
.card-aside-img {
	img {
		width: 80px;
		height: 80px;
		vertical-align: middle;
	}
	&.wrap-border img {
		border: 1px solid $border-color;
		padding: 3px;
	}
	position: relative;
	a {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
}
.card-item-desc {
	padding: 15px;
	h6 {
		margin-top: 5px;
	}
}
.card-blogs .card {
	border: none;
	box-shadow: none;
}
.card-blogs1 {
	.card {
		box-shadow: none;
		border-bottom: 1px solid $border-color;
		padding-bottom: 15px;
		margin-bottom: 10px;
		&:last-child {
			box-shadow: none;
			border-bottom: none;
			padding-bottom: 0;
			margin-bottom: 0;
		}
	}
	.card-item-desc .card-item-price {
		.newprice {
			font-weight: 600;
		}
		.oldprice {
			font-size: 14px;
		}
	}
}
.card-blogs2 .card {
	border: none;
	box-shadow: none;
}
.info-box small {
	font-size: 14px;
}
.info-box-icon {
	display: block;
	float: left;
	height: 90px;
	width: 90px;
	text-align: center;
	font-size: 45px;
	line-height: 90px;
	background: rgba(49, 45, 101, 0.2);
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
}
.info-box {
	display: block;
	min-height: 90px;
	background: #fff;
	width: 100%;
	border-radius: 2px;
	margin-bottom: 15px;
	box-shadow: 0 0 0 1px #dce3ec, 0 8px 16px 0 #dce3ec;
}
.info-box-icon>img {
	max-width: 100%;
}
.info-box-content {
	padding: 1.5rem 1.5rem;
	margin-left: 90px;
}
.info-box-number {
	display: block;
}
.info-box-text {
	display: block;
	font-size: 14px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-transform: uppercase;
}
.info-box-more {
	display: block;
}
.product-item {
	img {
		height: 180px;
		width: 180px;
	}
	span {
		top: 15px;
		left: 15px;
		position: absolute;
	}
}
.product-item-wrap .product-item-price {
	.newprice {
		font-size: 18px;
		font-weight: 600;
	}
	.oldprice {
		margin-left: 5px;
		font-size: 18px;
		font-weight: 600;
	}
}
.product-item1 {
	position: relative;
	.item-overlay {
		border-radius: .2rem .2rem 0 0;
		overflow: hidden;
		padding: 15px;
		text-align: center;
		img {
			height: 200px;
			width: 200px;
		}
	}
	.btn-overlay {
		position: absolute;
		display: none;
		text-align: center;
		top: 45%;
		margin: 0 auto;
		left: 0;
		width: 100%;
		height: 100%;
		right: 0;
		z-index: 1;
	}
	&:hover.product-item1:before {
		content: "";
		background-color: rgba(255, 255, 255, 0.8);
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
	}
}
.btn-overlay span {
	display: block;
	margin: .5rem;
	font-size: 16px;
	font-weight: 600;
}
.icon-card {
	display: inline-block;
	li {
		float: left;
		font-size: 13px;
		width: 50%;
		margin-bottom: .5rem;
	}
}
.product-item1:hover .btn-overlay {
	display: block;
	z-index: 1;
	opacity: 1;
}
.product-item2 .product-item2-img {
	img {
		height: 200px;
		width: 200px;
	}
	padding: 1.5rem 1.5rem;
}
.product-item2-rating {
	margin-right: 10px;
	display: inline-block;
	vertical-align: middle;
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;
	line-height: 1;
	white-space: nowrap;
	clear: both;
}
.product-item2 {
	border-bottom: 1px solid $border-color;
	background: #e7e9f1;
}
.owl-productimg img {
	background: #e7e9f1;
	padding: 20px;
}
.product-item2-desc .label-rating {
	margin-right: 10px;
	display: inline-block;
	vertical-align: middle;
}
.card-item-desc .card-item-desc-1 dt, dd {
	display: inline-block;
}
.card-item-desc {
	.card-item-desc-1 dd, dl {
		margin-bottom: 0;
	}
}
.product-details {
	.media {
		margin-top: 0;
	}
	.card-item-desc {
		padding: 0;
		margin-left: 15px;
		margin-top: 8px;
	}
}
.product-singleinfo .product-item2-align dt, dd {
	display: inline-block;
}
.product-singleinfo {
	.product-item2-align {
		dd {
			margin-left: 2rem;
			margin-bottom: 0;
			vertical-align: baseline;
		}
		dt {
			width: 100px;
			float: left;
		}
	}
	dl {
		margin-bottom: 5px;
	}
}
.oldprice {
	font-size: 25px;
	font-weight: 600;
}
.product-singleinfo .product-ship .product-item-price {
	.newprice {
		font-size: 25px;
		font-weight: 600;
	}
	.oldprice {
		margin-left: 5px;
	}
}
.product-multiinfo {
	.card {
		box-shadow: none;
		margin-bottom: 0;
		border-bottom: 1px solid $border-color;
	}
	.product-item img {
		width: 120px;
		height: 120px;
	}
}
.card-pay .tabs-menu {
	margin-bottom: 25px;
	border: 1px solid $border-color;
	border-radius: 4px;
	li {
		width: 33.3%;
		display: block;
		a {
			padding: .8rem 1rem;
			background: #ffffff;
			display: block;
			text-align: center;
			border-right: 1px solid $border-color;
		}
		&:last-child a {
			border-right: 0;
		}
		a.active {
			/* color: #fff; */
			border-radius: 0;
			text-align: center;
		}
	}
}
.single-productslide .product-gallery .product-item {
	margin-bottom: 10px;
	overflow: hidden;
	background-color: #fff;
}
.product-gallery {
	.product-item img {
		height: 350px;
		width: auto;
		display: inline-block;
		cursor: -webkit-zoom-in;
		cursor: zoom-in;
	}
	.product-sm-gallery {
		text-align: center;
		.item-gallery {
			img {
				max-width: 100%;
				max-height: 100%;
				-o-object-fit: cover;
				object-fit: cover;
				border-radius: 2px;
				cursor: -webkit-zoom-in;
				cursor: zoom-in;
			}
			width: 80px;
			height: 80px;
			border: 1px solid $border-color;
			padding: 5px;
			display: inline-block;
			overflow: hidden;
			line-height: 4.5;
		}
	}
}
.single-productslide .product-gallery-data {
	padding: 30px;
}
.product-gallery-data .product-gallery-data1 dt, dd {
	display: inline-block;
}
.product-gallery-data {
	dl {
		margin-bottom: 5px;
	}
	.product-gallery-data1 {
		dt {
			width: 100px;
			float: left;
		}
		dd {
			margin-left: 2rem;
			margin-bottom: 0;
			vertical-align: baseline;
		}
	}
}
.product-gallery-rating {
	margin-right: 10px;
	display: inline-block;
	vertical-align: middle;
	list-style: none;
	margin: 0;
	font-size: 18px;
	padding: 0;
	position: relative;
	line-height: 1;
	white-space: nowrap;
	clear: both;
}
.product-gallery-rats {
	margin-top: 20px;
}
.product-gallery-data {
	.label-rating {
		margin-right: 10px;
		display: inline-block;
		vertical-align: middle;
	}
	.product-gallery-quty dt {
		display: inline-block;
	}
}
dd, .product-gallery-data .product-gallery-size dt, dd {
	display: inline-block;
}
.product-gallery-data .product-gallery-size .product-gallery-checks label {
	display: inline-block;
	margin-left: 10px;
}
.product-card-img img {
	height: 180px;
	width: 180px;
}
.product-card-icons {
	position: absolute;
	top: 15px;
	left: 15px;
	right: 15px;
	display: inline-block;
}
.product-card-footer .product-footer-wrap .footer-wrap-price {
	font-size: 16px;
	font-weight: 600;
	del {
		margin-left: 5px;
	}
}
.product-card4-footer .product-card4-wrap .product-card4-price h6, .product-card5-footer .product-card5-price h6 {
	font-size: 25px;
	font-weight: 600;
}
.product-card6-footer {
	.product-card6-price h6 {
		font-size: 25px;
		font-weight: 600;
	}
	.product-card6-info del {
		margin-right: 5px;
	}
}
.product-card7-footer .product-card7-price {
	h6 {
		font-size: 25px;
		font-weight: 600;
	}
	del, span {
		margin-left: 5px;
	}
}
.team-section .team-img img {
	max-width: 150px;
	box-shadow: 0 0 0 1px #dce3ec, 0 8px 16px 0 #dce3ec;
}
.item-single .item-single-desc .item-single-desc-list .listunorder {
	border: 0;
	padding: 5px;
	margin-bottom: 0;
	font-size: 15px;
}
.card-over {
	position: relative;
	img {
		vertical-align: middle;
	}
	.content {
		position: absolute;
		bottom: 0;
		background: #374254;
		opacity: .9;
		color: #fff;
		width: 100%;
		padding: 20px;
	}
}
.checklist-task .checklist-desc .check-data {
	span {
		font-size: 12px;
	}
	.check-icon {
		margin-top: 0;
		font-size: 14px;
		i {
			font-size: 14px;
			padding: 0 6px;
		}
	}
}
.carousel-bg {
	.prev-icon, .next-icon {
		background-color: rgba(49, 45, 101, 0.7);
		padding: 5px 15px;
		border-radius: 100px;
		font-size: 20px;
	}
}
.banner1 {
	.carousel-inner img {
		width: 100%;
		max-height: 460px;
	}
	.carousel-item:before {
		content: '';
		display: block;
		position: absolute;
		background: rgba(49, 45, 101, 0.6);
		width: 100%;
		height: 100%;
		right: 0;
		top: 0;
	}
	.carousel-control {
		width: 0;
		&.left, &.right {
			opacity: 1;
			background-image: none;
			background-repeat: no-repeat;
			text-shadow: none;
		}
		&.left span, &.right span {
			padding: 15px;
		}
		.glyphicon-chevron-left, .glyphicon-chevron-right, .icon-next, .icon-prev {
			position: absolute;
			top: 45%;
			z-index: 5;
			display: inline-block;
		}
		.glyphicon-chevron-left, .icon-prev {
			left: 0;
		}
		.glyphicon-chevron-right, .icon-next {
			right: 0;
		}
		&.left span, &.right span {
			background: rgba(255, 255, 255, 0.1);
			color: #fff;
		}
		&.left span:hover, &.right span:hover {
			background: rgba(255, 255, 255, 0.3);
		}
	}
	.header-text {
		position: absolute;
		left: 0;
		right: 0;
		color: #fff;
	}
	.slider .header-text {
		top: 29%;
	}
	.header-text {
		h2 {
			font-size: 40px;
		}
		h3 {
			font-size: 25px;
		}
		h2 span {
			padding: 10px;
		}
		h3 span {
			padding: 15px;
		}
	}
}
.input-indec {
	.quantity-left-minus.btn {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		border-right: 0;
	}
	.quantity-right-plus.btn {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		border-left: 0;
	}
}
.cat-item {
	position: relative;
	a {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
	.cat-img img {
		width: 60px;
		height: 60px;
		margin: 0 auto;
	}
	.cat-desc {
		margin-top: 20px;
		color: #3f3e50;
	}
}
.about img {
	width: 60px;
	height: 60px;
	text-align: center;
}
.header-main {
	.top-bar .contact {
		padding-right: 15px;
		li {
			margin: 0;
			padding: 8px 0;
			padding-left: 15px;
			border-left: 1px solid $border-color;
			height: 40px;
			line-height: 26px;
		}
	}
	.top-bar-right .custom {
		display: flex;
		border-right: 1px solid $border-color;
		li {
			padding: 0px 12px;
			font-size: 13px;
			border-left: 1px solid $border-color;
			line-height:40px;
			&:last-child {
				margin-right: 0;
			}
			a{
				line-height:40px;
			}
			.dropdown-menu .dropdown-item{
				line-height:22px;
			}
		}
	}
}
.ace-responsive-menu {
	margin-right: 1rem;
}
.header-main {
	.ace-responsive-menu li a {
		color: #3f3e50;
		&:hover {
			color: #fff;
		}
		&:focus {
			color: #000;
		}
	}
	.post-btn {
		float: right;
		margin-left: auto;
	}
}
.footer-main {
	padding-top: 2rem;
	padding-bottom: 2rem;
	border-top: 1px solid rgba(167, 180, 201, 0.2);
	border-bottom: 1px solid rgba(167, 180, 201, 0.2);
}
footer .border-top {
	border-top: 1px solid rgba(167, 180, 201, 0.2) !important;
}
.footer-main .form-control {
	border: 0 !important;
}
.footer-payments {
	padding-left: 0;
	list-style: none;
	margin: 0;
	line-height: 1.25rem;
	li {
		display: inline-block;
		padding: 0 6px;
		font-size: 20px;
	}
}
.footer-main img {
	border: 0px solid rgba(255, 255, 255, 0.1);
	padding: 0;
	border-radius: 5px;
}
.sptb {
	padding-top: 3rem;
	padding-bottom: 3rem;
}
.sptb-1 {
	padding-top: 8.5rem;
	padding-bottom: 4.5rem;
}
.sptb-12 {
	padding-top: 8.5rem;
	padding-bottom: 8.5rem;
}
.sptb-2 {
	padding-top: 6.2rem;
	padding-bottom: 11rem;
}
.sptb-21 {
	padding-top: 4.2rem;
	padding-bottom: 14rem;
}
.sptb-22 {
	padding-top: 9rem;
	padding-bottom: 15rem;
}
.sptb-3, .sptb-4 {
	padding-top: 6.2rem;
	padding-bottom: 11rem;
}
.sptb-8 {
	padding-top: 7rem;
	padding-bottom: 12rem;
}
.categories {
	margin-top: -65px;
	margin-bottom: 65px;
}
.categories2 {
	margin-top: -50px;
}
.categories-1 {
	margin-top: -120px;
}
.section-title {
	p {
		text-align: center;
		font-size: 16px;
		margin-bottom: 0;
		color: #a1a0b9;
	}
	padding-bottom: 3rem;
	h1, h2 {
		margin-bottom: .5rem;
		color: #4f4b8b;
		font-size: 34px;
		font-weight: 700;
		z-index: 1;
	}
}
.item-all-cat {
	.item-all-card a:hover .item-all-text h5 {
		color: #3f3e50 !important;
	}
	.category-type .item-all-card:hover {
		background: #f6f7fb;
	}
}
.banners-image .horizontalMenu>.horizontalMenu-list>li>a {
	color: #3f3e50 !important;
}
.sticky-wrapper.absolute.banners {
	.horizontal-main {
		background: #fff;
		.horizontalMenu>.horizontalMenu-list>li>a {
			color: #3f3e50 !important;
			>.fa {
				color: #3f3e50 !important;
			}
		}
	}
}
.banner-1 {
	.item-search-tabs .search-background .form-control {}
	.search-background .form-control {
		border: 1px solid $form-background;
	}
}
.search-background {
	.form-control {
		border: 1px solid $form-background;
	}
	&.bg-transparent .form-control {
		border: 1px solid $border-color;
	}
}
.header-text1 {
	.form-control {
		border: 1px solid $form-background;
	}
}
.banner-1 {
	.search-background {
		.form1 {
			border-left: 1px solid #fff !important;
		}
		.btn-lg {
			position: relative;
		}
	}
	.header-text, .header-text1 {
		left: 0;
		right: 0;
		color: #fff;
	}
	.header-text {
		h1 {
			margin-bottom: .3rem;
			font-weight: 600;
			font-size: 3rem;
		}
		p {
			margin-bottom: 1.5rem;
			font-size: 18px;
		}
	}
}
.banner-2 {
	.form-control {
		border-top-left-radius: 2px;
		border-top-right-radius: 2px;
		border: 0 !important;
	}
	.header-text {
		left: 0;
		right: 0;
		color: #fff;
		p {
			margin-bottom: 0;
			font-size: 16px;
		}
	}
}
.bannerimg {
	padding: 5rem 0 5rem 0;
	background-size: cover;
	.header-text h1 {
		margin-bottom: 0;
		font-size: 24px;
		font-weight: 600;
	}
}
.about-1 {
	position: relative;
	.header-text {
		left: 0;
		right: 0;
		color: #fff;
	}
}
.country .dropdown-menu {
	height: 233px;
	overflow: scroll;
}
#price {
	border: 0;
	font-weight: 600;
	font-size: 15px;
	width: 150px !important;
}
.showmore-button {
	text-align: center;
}
.showmore-button-inner {
	display: inline-block;
	padding: 10px 19px;
	line-height: 14px;
	font-size: 14px;
	border-radius: 5px;
	text-decoration: none;
	cursor: pointer;
	margin-top: 20px;
}
.showmore-button1 {
	text-align: center;
	.showmore-button1-inner {
		display: inline-block;
		line-height: 14px;
		font-size: 14px;
		color: #cecde0;
		text-decoration: none;
		cursor: pointer;
		margin-top: 8px;
		margin-bottom: 20px;
	}
}
.fea-ad .card, .cat-slide .card {
	margin-bottom: 0;
}
.fade.in {
	opacity: 1;
}
.wishlist {
	background: rgba(0, 0, 0, 0.7);
	&.active {
		background: #e62a72;
	}
}
.constuction-logo {
	border-bottom: 3px solid;
	border-radius: 5px;
}
.coupon-code .coupon {
	background: rgba(242, 187, 26, 0.1);
	border: 2px dashed #f2bb1a;
	text-align: center;
	border-radius: 2px;
}
.page-section {
	background: url(../images/banners/banner.png);
	background-position: center;
	background-size: cover;
	background-attachment: fixed;
	&:before {
		position: fixed;
		content: '';
		width: 100%;
		height: 100%;
		display: block;
		z-index: 1;
		left: 0;
		right: 0;
		background: linear-gradient(-225deg, rgba(72, 1, 255, 0.9) 0, rgba(121, 24, 242, 0.9) 48%, rgba(172, 50, 228, 0.9) 100%);
	}
}
.page-section2{
	background: url(../images/banners/error.jpg);
	background-position: center;
	background-size: cover;
	background-attachment: fixed;
	&:before {
		position: fixed;
		content: '';
		width: 100%;
		height: 100%;
		display: block;
		z-index: 1;
		left: 0;
		right: 0;
		background: transparent !important;
	}
	.img-absolute-bottom{
		right:0;
		left:auto;
	}
}
.construction {
	z-index: 1;
	.btn {
		&.btn-icon {
			text-align: center;
			padding: 0;
			font-size: 16px;
			color: rgba(255, 255, 255, 0.9);
			border-radius: 50%;
			background: rgba(255, 255, 255, 0.2);
			width: 3rem;
			height: 3rem;
			line-height: 3rem;
		}
		border-radius: 5px;
	}
	.form-control {
		border: 1px solid #fff;
		border-radius: 5px;
		padding-left: 20px;
		width: 60%;
	}
	.input-group-1 {
		margin: 0 auto;
	}
	h3 {
		color: #fff;
		font-size: 3.2rem;
		font-weight: 800 !important;
		margin-bottom: .4em !important;
		letter-spacing: 0;
		padding-bottom: 0;
		line-height: 1.2;
	}
	p {
		margin-top: 10px;
		margin-bottom: 0;
		font-weight: 400;
		font-size: 18px;
		color: rgb(255, 255, 255);
	}
}
.page-section .card {
	background: rgb(255, 255, 255);
}
.horizontalMenu>.horizontalMenu-list>li>a.btn:hover {
	background: #fb9512;
}
.item-user span i {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background: $border-color;
	text-align: center;
	line-height: 30px;
	font-size: 12px;
}
.profile-pic {
	text-align: center;
	position: relative;
}
.profile-pic-2 {
	text-align: left;
	margin-top: -70px;
	position: relative;
}
.edit-profile-icon {
	position: absolute;
	top: 0;
	left: 8px;
	text-align: center;
	i {
		width: 35px;
		height: 35px;
		line-height: 35px;
		border-radius: 50%;
		display: block;
	}
}
.profile-pic .d-md-flex {
	text-align: left;
}
#exzoom {
	width: 100%;
}
:focus {
	outline: 0 !important;
}
.header-menu1 {
	float: none !important;
}
.register-right .nav-tabs {
	.nav-link {
		padding: 12px 25px;
		margin: 1px;
		text-align: center;
		display: block;
		border-radius: 5px;
		background: #ffffff;
		border: 1px solid $border-color;
	}
	background: #fff;
	margin: 0 auto;
	border-radius: 5px;
	box-shadow: 0 10px 40px 0 rgba(62, 57, 107, 0.1), 0 2px 9px 0 rgba(62, 57, 107, 0.1);
	.nav-item {
		display: block;
		text-align: center;
	}
}
.gradient-icon {
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.nice-select.form-control{
	border:1px solid rgba(255,255,255,0.15) !important;
}
/*-------- Custom-01 CSS -------*/
