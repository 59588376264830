/*-------- Customaization Css -------*/

.counter {
	font-size: 35px;
	margin-top: 0px;
	margin-bottom: 10px;
}
.counter-1 {
	font-size: 40px;
	font-weight: 600;
}
.todo-item {
	+.todo-item {
		margin-top: 8px;
	}
	.checkbox {
		margin-bottom: 6px;
	}
	input:checked+span {
		text-decoration: line-through;
	}
}
.checkbox {
	font-weight: 400;
	position: relative;
	display: block;
	line-height: 18px;
	span {
		padding-left: 15px;
		&:empty {
			float: left;
		}
		&:after {
			line-height: 18px;
			position: absolute;
		}
		&:before {
			line-height: 18px;
			position: absolute;
			content: '';
			width: 16px;
			height: 16px;
			background-color: $border-color;
			border: 1px solid #c6c0de;
			top: 1px;
			left: 0;
		}
		&:after {
			top: 1px;
			left: 0;
			width: 16px;
			height: 16px;
			content: '\f00c';
			font-family: FontAwesome;
			font-size: 9px;
			text-align: center;
			color: #fff;
			line-height: 17px;
			display: none;
		}
	}
	input[type=checkbox] {
		opacity: 0;
		margin: 0 5px 0 0;
		&:checked+span:after {
			display: block;
		}
		&[disabled]+span {
			opacity: .75;
			&:after, &:before {
				opacity: .75;
			}
		}
	}
}
.checkbox-inline {
	display: inline-block;
}
.media .chat {
	i, a {
		font-size: 13px;
	}
}
.iconfont {
	h2 {
		font-size: 45px;
		font-weight: 600;
		margin-bottom: 10px !important;
	}
	h5 {
		font-size: 16px;
		font-weight: 600;
	}
	i {
		font-size: 15px;
	}
}
.wx h1 {
	font-size: 65px !important;
	margin-bottom: 15px;
	margin-top: 0;
}
.chat {
	li {
		padding: 15px;
		overflow: hidden;
		display: flex;
	}
	.avatar {
		width: 40px;
		height: 40px;
		position: relative;
		display: block;
		z-index: 2;
		border-radius: 100%;
		-webkit-border-radius: 100%;
		-moz-border-radius: 100%;
		-ms-border-radius: 100%;
		background-color: rgba(255, 255, 255, 0.9);
		img {
			width: 40px;
			height: 40px;
			border-radius: 100%;
			-webkit-border-radius: 100%;
			-moz-border-radius: 100%;
			-ms-border-radius: 100%;
			background-color: rgba(255, 255, 255, 0.9);
			-webkit-touch-callout: none;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
		}
	}
	.day {
		position: relative;
		display: block;
		text-align: center;
		color: silver;
		height: 20px;
		box-shadow: 0 0 0 1px rgba(243, 247, 253, 0.12), 0 8px 16px 0 rgba(243, 247, 253, 0.24);
		line-height: 38px;
		margin-top: 5px;
		margin-bottom: 20px;
		cursor: default;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
	}
}
.other {
	.msg {
		order: 1;
		border: 1px solid $border-color;
		border-top-left-radius: 0;
		box-shadow: 0 0 0 1px rgba(243, 247, 253, 0.12), 0 8px 16px 0 rgba(243, 247, 253, 0.24);
	}
	&:before {
		content: "";
		position: relative;
		top: 0;
		right: 0;
		left: 50px;
		width: 0;
		height: 0;
		border: 5px solid $border-color;
		border-left-color: transparent;
		border-bottom-color: transparent;
	}
}
.self {
	justify-content: flex-end;
	align-items: flex-end;
	.msg {
		order: 1;
		border: 1px solid $border-color;
		border-bottom-right-radius: 0;
		box-shadow: 0 0 0 1px rgba(243, 247, 253, 0.12), 0 8px 16px 0 rgba(243, 247, 253, 0.24);
	}
	.avatar {
		order: 2;
		&:after {
			content: "";
			position: relative;
			display: inline-block;
			bottom: -14px;
			right: 26px;
			width: 0;
			height: 0;
			border: 5px solid $border-color;
			border-right-color: transparent;
			border-top-color: transparent;
			box-shadow: 0 0 0 1px rgba(243, 247, 253, 0.12), 0 8px 16px 0 rgba(243, 247, 253, 0.24);
		}
	}
}
.msg {
	background: #fff;
	min-width: 50px;
	margin: 0 10px;
	padding: 10px;
	border-radius: 2px;
	box-shadow: 0 2px 0 rgba(49, 45, 101, 0.07);
	p {
		font-size: 13px;
		margin: 0 0 .2rem 0;
		color: #313148;
	}
	img {
		position: relative;
		display: block;
		width: 450px;
		border-radius: 5px;
		box-shadow: 0 0 3px #eee;
		transition: all 0.4s cubic-bezier(0.565, -0.26, 0.255, 1.41);
		cursor: default;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
	}
	time {
		font-size: .7rem;
		color: #cecde0;
		margin-top: 3px;
		float: right;
		cursor: default;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		&:before {
			content: "\f017";
			color: #cecde0;
			font-family: FontAwesome;
			display: inline-block;
			margin-right: 4px;
		}
	}
}
.social-title {
	text-transform: uppercase;
	letter-spacing: 1px;
	text-shadow: none;
	color: #fff !important;
}
.socials {
	display: flex;
	li {
		margin: 0 12px;
		margin-right: 15px;
	}
}
.text-shadow1 {
	text-shadow: 0 10px 10px rgba(196, 192, 220, 0.2);
}
.count {
	font-size: 37px;
	margin-top: 10px;
	margin-bottom: 10px;
}
.card .card-block {
	padding: 15px;
}
.user-card .card-block .user-image {
	position: relative;
	margin: 0 auto;
	display: inline-block;
	padding: 5px;
	width: 110px;
	height: 110px;
}
.card .card-block p {
	line-height: 1.4;
	margin-top: 10px;
}
.user-card .card-block {
	.activity-leval li {
		display: inline-block;
		width: 15%;
		height: 4px;
		margin: 0 3px;
		background-color: #e9ebf9;
	}
	.counter-block {
		color: #fff;
	}
	.user-social-link i {
		font-size: 30px;
	}
}
.widget-user {
	.widget-user-header {
		padding: 20px;
		height: 120px;
	}
	.widget-user-username {
		margin-top: 0;
		margin-bottom: 5px;
		font-size: 25px;
		font-weight: 300;
		text-shadow: 0 1px 1px rgba(49, 45, 101, 0.2);
		color: #fff;
	}
	.widget-user-desc {
		margin-top: 0;
		color: #fff;
	}
	.widget-user-image {
		position: absolute;
		top: 65px;
		left: 50%;
		margin-left: -45px;
		>img {
			width: 90px;
			height: auto;
			border: 3px solid #fff;
		}
	}
	.user-wideget-footer {
		padding-top: 30px;
	}
}
.user-wideget-footer {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	border-top: 1px solid #f4f4f4;
	padding: 10px;
	background-color: #fff;
}
.user-wideget .border-right {
	border-right: 1px solid #f4f4f4;
}
.description-block {
	display: block;
	margin: 10px 0;
	text-align: center;
	> {
		.description-header {
			margin: 0;
			padding: 0;
			font-weight: 600;
			font-size: 16px;
		}
		.description-text {
			text-transform: uppercase;
			color: #3f3e50;
		}
	}
}
.socailicons {
	.fa {
		font-size: 70px;
		text-align: right;
		position: absolute;
		top: 13px;
		right: -5px;
		outline: 0;
	}
	color: #fff;
	text-shadow: 1px 1px 2px rgba(49, 45, 101, 0.5);
	a {
		text-decoration: none;
	}
}
.twitter1 {
	background-color: #00acee !important;
}
.google-plus1 {
	background-color: #dd4b39 !important;
}
.facebook-like1 {
	background-color: #3b5998 !important;
}
.pinterest1 {
	background-color: #bd081c !important;
}
.card-blog-overlay6 i {
	font-size: 3rem;
}
.card-counter2 i {
	font-size: 4.5em;
}
#count-down {
	margin: 3rem;
	.clock-presenter {
		height: 80px;
		padding: 0;
		text-align: center;
		.digit {
			margin-top: 20px;
			font-size: 3rem;
			line-height: 46px;
			height: 50px;
			padding: 0 7px;
			display: inline-block;
			overflow: hidden;
			text-align: center;
			position: relative;
			cursor: default;
			color: #fff;
			background-size: cover;
			border-radius: 5px;
		}
		.note {
			position: relative;
			margin-bottom: 0;
			cursor: default;
			font-size: 16px;
			opacity: .7;
		}
	}
}
.background {
	position: fixed;
	top: 50%;
	left: 50%;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	-webkit-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
	z-index: 0;
}
.overlay {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	opacity: .7;
	z-index: 1;
}
.user-head {
	.inbox-avatar {
		float: left;
		width: 65px;
		img {
			border-radius: 4px;
		}
	}
	.user-name {
		display: inline-block;
		margin: 0 0 0 10px;
		h5 {
			font-size: 14px;
			font-weight: 400;
			margin-bottom: 0;
			margin-top: 15px;
			a {
				color: #fff;
			}
		}
		span a {
			color: #87e2e7;
			font-size: 12px;
		}
	}
}
a.mail-dropdown {
	background: none repeat scroll 0 0 #80d3d9;
	border-radius: 2px;
	color: #01a7b3;
	font-size: 10px;
	margin-top: 20px;
	padding: 3px 5px;
}
.btn-compose {
	background: none repeat scroll 0 0 #ff6c60;
	color: #fff;
	padding: 12px 0;
	text-align: center;
	width: 100%;
	&:hover {
		background: none repeat scroll 0 0 #f5675c;
		color: #fff;
	}
}
ul.inbox-nav {
	display: inline-block;
	margin: 0;
	padding: 0;
	width: 100%;
}
.inbox-divider {
	border-bottom: 1px solid #d5d8df;
}
ul {
	&.inbox-nav li {
		display: inline-block;
		line-height: 45px;
		width: 100%;
		a {
			color: #6a6a6a;
			display: inline-block;
			line-height: 45px;
			padding: 0 20px;
			width: 100%;
			&:focus, &:hover {
				background: none repeat scroll 0 0 #d5d7de;
				color: #6a6a6a;
			}
		}
		&.active a {
			background: none repeat scroll 0 0 #d5d7de;
			color: #6a6a6a;
		}
		a {
			i {
				color: #6a6a6a;
				font-size: 16px;
				padding-right: 10px;
			}
			span.label {
				margin-top: 13px;
			}
		}
	}
	&.labels-info li {
		h4 {
			color: #5c5c5e;
			font-size: 13px;
			padding-left: 15px;
			padding-right: 15px;
			padding-top: 5px;
			text-transform: uppercase;
		}
		margin: 0;
		a {
			border-radius: 0;
			color: #6a6a6a;
			&:focus, &:hover {
				background: none repeat scroll 0 0 #d5d7de;
				color: #6a6a6a;
			}
			i {
				padding-right: 10px;
			}
		}
	}
}
.nav.nav-pills.nav-stacked.labels-info p {
	color: #9d9f9e;
	font-size: 11px;
	margin-bottom: 0;
	padding: 0 22px;
}
.inbox-head {
	border-radius: 0 4px 0 0;
	padding: 10px;
	border-radius: 5px;
	h3 {
		display: inline-block;
		font-weight: 400;
		margin: 0;
	}
	.sr-input {
		border: medium none;
		border-radius: 4px 0 0 4px;
		box-shadow: none;
		color: #8a8a8a;
		float: left;
		height: 30px;
		padding: 0 10px;
	}
	.sr-btn {
		background: none repeat scroll 0 0 #eef2f9;
		border: medium none;
		border-radius: 0 4px 4px 0;
		color: #fff;
		height: 30px;
		padding: 0 20px;
	}
}
.mail-option {
	display: inline-block;
	margin-bottom: 10px;
	width: 100%;
	.btn-group, .chk-all {
		margin-right: 5px;
	}
	.btn-group a.btn, .chk-all {
		background: none repeat scroll 0 0 $body-color;
		border: 1px solid #e1ecfc;
		border-radius: 3px !important;
		color: #3d4e67;
		display: inline-block;
		padding: 5px 10px;
	}
}
.inbox-pagination a.np-btn {
	background: none repeat scroll 0 0 $body-color;
	border: 1px solid #e1ecfc;
	border-radius: 3px !important;
	color: #3d4e67;
	display: inline-block;
	padding: 5px 15px;
	margin-left: 5px;
}
.mail-option {
	.chk-all input[type=checkbox] {
		margin-top: 0;
	}
	.btn-group a.all {
		border: medium none;
		padding: 0;
	}
}
.inbox-pagination li span {
	display: inline-block;
	margin-right: 5px;
	margin-top: 7px;
}
.inbox-body .modal .modal-body {
	input, textarea {
		border: 1px solid #e6e6e6;
		box-shadow: none;
	}
}
ul.inbox-pagination {
	float: right;
	li {
		float: left;
	}
}
.modal-header h4.modal-title {
	font-weight: 500;
}
.modal-body label {
	font-weight: 400;
}
.heading-inbox h4 {
	border-bottom: 1px solid #ddd;
	color: #444;
	font-size: 18px;
	margin-top: 20px;
	padding-bottom: 10px;
}
.sender-info {
	margin-bottom: 20px;
	img {
		height: 30px;
		width: 30px;
	}
}
.sender-dropdown {
	background: none repeat scroll 0 0 $border-color;
	color: #777;
	font-size: 10px;
	padding: 0 3px;
}
.view-mail a {
	color: #ff6c60;
}
.attachment-mail {
	margin-top: 30px;
	ul {
		display: inline-block;
		margin-bottom: 30px;
		width: 100%;
		li {
			float: left;
			margin-bottom: 10px;
			margin-right: 10px;
			width: 150px;
			img {
				width: 100%;
			}
			span {
				float: right;
			}
		}
	}
	.file-name {
		float: left;
	}
	.links {
		display: inline-block;
		width: 100%;
	}
}
.fileinput-button {
	float: left;
	margin-right: 4px;
	overflow: hidden;
	position: relative;
	background: none repeat scroll 0 0 $border-color;
	border: 1px solid #e6e6e6;
	input {
		cursor: pointer;
		direction: ltr;
		font-size: 23px;
		margin: 0;
		opacity: 0;
		position: absolute;
		right: 0;
		top: 0;
		transform: translate(-300px, 0) scale(4);
	}
}
.fileupload-buttonbar {
	.btn, .toggle {
		margin-bottom: 5px;
	}
}
.files .progress {
	width: 200px;
}
.fileupload-processing .fileupload-loading {
	display: block;
}
* html .fileinput-button {
	line-height: 24px;
	margin: 1px -3px 0 0;
}
ul {
	list-style-type: none;
	padding: 0;
	margin: 0;
}


/*-------- Chart Heights -------*/

.chart-visitors {
	min-height: 18rem;
}
.chart-tasks {
	height: 15rem;
}
.chart-donut, .chart-pie {
	height: 21rem;
}
.chartsh {
	height: 16rem;
}
.chartwidget {
	height: 17rem;
}
.chartmorris {
	height: 19rem;
}
.dropshadow {
	-webkit-filter: drop-shadow(0 -6px 4px rgb(227, 232, 239));
	filter: drop-shadow(0 -6px 4px rgb(227, 232, 239));
}
/*-------- Chart Heights -------*/

.select-languages {
	color: #3f3e50;
	border-radius: 5px;
	&:focus {
		color: #3d4e67;
		background-color: #fff;
	}
}
.img-flag {
	width: 25px;
	height: 17px;
	margin-top: -4px;
}
#image-slider{
	&.carousel {
		.carousel-control-next{
			right: 10px !important;
			background: $black-3 !important;
			width: 30px !important;
			border-radius: 50%;
			height: 30px !important;
				top: 45%;
		} 
		.carousel-control-prev{
			left: 10px !important;
			background: $black-3 !important;
			width: 30px !important;
			border-radius: 50%;
			height: 30px !important;
				top: 45%;
		} 
	} 
}
#image-slider1 {
	&.carousel {
		.carousel-control-next{
			right: 10px !important;
			background: $black-3 !important;
			width: 30px !important;
			border-radius: 50%;
			height: 30px !important;
				top: 45%;
		} 
		.carousel-control-prev{
			left: 10px !important;
			background: $black-3 !important;
			width: 30px !important;
			border-radius: 50%;
			height: 30px !important;
				top: 45%;
		} 
	} 
}
/*-------- Customaization Css -------*/
