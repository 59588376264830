/*-------- Border Radius -------*/

.rounded {
	border-radius: 3px !important;
}
.rounded7 {
	border-radius: 7px !important;
}
.rounded-top {
	border-top-left-radius: 3px !important;
	border-top-right-radius: 3px !important;
}
.rounded-right {
	border-top-right-radius: 3px !important;
	border-bottom-right-radius: 3px !important;
}
.rounded-bottom {
	border-bottom-right-radius: 3px !important;
	border-bottom-left-radius: 3px !important;
}
.rounded-left {
	border-top-left-radius: 3px !important;
	border-bottom-left-radius: 3px !important;
}

.br-7 {
	border-radius: 7px;
}
.br-10 {
	border-radius: 1rem;
}
.br-tl-7 {
	border-top-left-radius: 7px !important;
}
.br-tl-10 {
	border-top-left-radius: 1rem !important;
}
.br-bl-7 {
	border-bottom-left-radius: 7px !important;
}
.br-bl-10 {
	border-bottom-left-radius: 1rem !important;
}
.br-tr-7 {
	border-top-right-radius: 7px !important;
}
.br-tr-10 {
	border-top-right-radius: 1rem !important;
}
.br-br-7 {
	border-bottom-right-radius: 7px !important;
}
.br-br-10 {
	border-bottom-right-radius: 1rem !important;
}
.br-4 {
	border-radius: 4px;
}
.br-tl-4 {
	border-top-left-radius: 4px !important;
}
.br-bl-4 {
	border-bottom-left-radius: 4px !important;
}
.br-tr-4 {
	border-top-right-radius: 4px !important;
}
.br-br-4 {
	border-bottom-right-radius: 4px !important;
}
.br-5 {
	border-radius: 5px;
}
.br-tl-5 {
	border-top-left-radius: 5px !important;
}
.br-bl-5 {
	border-bottom-left-radius: 5px !important;
}
.br-tr-5 {
	border-top-right-radius: 5px !important;
}
.br-br-5 {
	border-bottom-right-radius: 5px !important;
}
.br-100 {
	border-radius: 100% !important;
}
.br-2 {
	border-radius: 2px !important;
}
.br-tl-2 {
	border-top-left-radius: 2px !important;
}
.br-bl-2 {
	border-bottom-left-radius: 2px !important;
}
.br-tr-2 {
	border-top-right-radius: 2px !important;
}
.br-br-2 {
	border-bottom-right-radius: 2px !important;
}
.br-0 {
	border-radius: 0px !important;
}
.br-tl-0 {
	border-top-left-radius: 0px !important;
}
.br-bl-0 {
	border-bottom-left-radius: 0px !important;
}
.br-tr-0 {
	border-top-right-radius: 0px !important;
}
.br-br-0 {
	border-bottom-right-radius: 0px !important;
}
/*-------- Border Radius -------*/